import React, { useEffect, useState } from "react";
import Layout from "../components/ui/layout";
import Baner from "../components/ui/transport/baner";
import Messages from "../components/ui/transport/messages";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";
import axios from "../api/without_user_token";

export default function Transport(){
    const [data, setData]   = useState([]);
    const {t, i18n}         = useTranslation('global');
    const messages          = [];

    useEffect(() => {
        document.title = t('Key messages about transport');
      }, []);
    
    useEffect(() => {
        
        const getDataTextTransport = () => {
            axios.get('get_data_text_by_domaine_name', {
                params : { lang: i18n.language, 'name': t("Transportation") },
                headers: { 'Content-Type': 'application/json' }
            }).then((response) => {
                if (response.status === 200) {
                    setData(response.data)
                }
            }).catch((err) => {
                console.log('Error occur in getDataTextTransport: ' + err);
            })
        }

        getDataTextTransport()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n.language]);
    
    if (data.length !== 0) {
        const dataset = data[0].sub_domain_name;
        for (let i = 0; i < dataset.length; i++) {
            let text = [];
            if (dataset[i].data_text.length > 0) {
                for (let j = 0; j < dataset[i].data_text.length; j++) {
                    text.push({ 
                        texte: dataset[i].data_text[j].resume, 
                        link: dataset[i].data_text[j].link 
                    });
                }
            }
            messages.push({ 
                actor: dataset[i].name, 
                actor_uuid: dataset[i].uuid, 
                textes: text, 
                link: dataset[i].link 
            });
        }
    }

    return(
        <Layout>
            <Baner />
            {data.length === 0 ? (<div style={{padding: "2rem 10rem"}}> <CircularProgress /> </div>) : <Messages messages={messages} />}
        </Layout>
    )
}