/* eslint-disable jsx-a11y/no-distracting-elements */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import {
    Grid, Button, Popover, Typography,
    AppBar, Tabs, Tab, Toolbar, IconButton,
    SwipeableDrawer, List, ListItem, ListItemText,
    ListItemButton, ListItemIcon, Divider,
    alpha, Box, Avatar, Container, Accordion, AccordionSummary, AccordionDetails
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Link, useNavigate } from 'react-router-dom';
import Arrow from '@mui/icons-material/ArrowDropDownSharp';
import MenuIcon from '@mui/icons-material/Menu';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import LogoCilss from "../../images/cilssdef.png";
import Cedeao from "../../images/cedeao.png";
import Uemoa from "../../images/uemoa.png";
import Baner from "../../images/baner.png"
import CilssCard from "../../images/cilssCard3.png"
import Logo from "../../images/logodef.png"
import mPhoto from "../../images/mobille_app.jpg"
import sd3c from "../../images/sd3c.png"

import praps from "../../images/praps2.png"

import { ExpandMore, InstallMobile } from '@mui/icons-material';

export default function Header() {
    const matchesMD = useMediaQuery("(max-width: 1200px)");
    const [openDrawer, setOpenDrawer] = useState(false);
    const [t, i18n] = useTranslation('global');
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [ipAdress, setIpAdress] = useState('');
    const [geoInfo, setGeoInfo] = useState({});
    const [countryNumber, setCountryNumber] = useState();
    const [sub, setSub] = useState(null);
    const [mobileSub, setMobileSub] = useState(null);
    const subOpen = Boolean(sub)
    const mobileSubOpen = Boolean(mobileSub)
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const USER_LANG = navigator.language || navigator.userLanguage;
    const [parentMenu, setParentMenu] = useState(-1)
    const APP_LANG = {
        'fr-FR': 'fr',
        'fr': 'fr',
        'en-US': 'en',
        'en': 'en',
        'ar': 'ar',
        'pt': 'pt',
    };


    const navigate = useNavigate()

    const COUNTRY_NUMBER = {
        BF: 3488,
        GM: 1616,
        SN: 200800,
        ML: 36065,
        TD: 1324
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handlePopoverOpen = (event, i) => {
        setSub(event.currentTarget);
        setParentMenu(i)
    };

    const handleMobilePopover = (event) => {
        setMobileSub(event.currentTarget)
    }

    const handleMobilePopoverClose = () => {
        setMobileSub(null)
    }

    const handlePopoverClose = () => {
        setSub(null);
    };

    const handleChangeLang = (lang) => {
        localStorage.setItem('lang', lang);
        i18n.changeLanguage(lang);
        handleClose();
    }

    const routes = [
        { name: t("menu.home"), id: "/", link: "/" },
        {
            name: t("menu.messages"), id: "messages", link: "/#", sub_menu: [
                { name: t("menu.Trade"), id: "commerce", link: "/messages/commerce" },
                { name: t("menu.Transport"), id: "transport", link: "/messages/transport" },

            ]
        },
        {
            name: t("menu.formalities_and_procedures"), id: "formproc", link: "/#", sub_menu: [
                { name: t("menu.Formalities"), id: "formalities", link: "/formproc/formalities" },
                { name: t("menu.Procedures"), id: "procedures", link: "/formproc/procedures" },
            ]
        },
        { name: t("menu.medias"), id: "media", link: "/media" },
        { name: t("menu.documenthec"), id: "resources", link: "/#",  sub_menu: [
            { name: t("menu.procedure_and_commerciale"), id: "procedureComm", link: "/resources/procedures-commerciales" },
            { name: t("menu.bulletin_information"), id: "bulletin", link: "/resources/bulletin-information" },
            { name: t("menu.doccommerce"), id: "doccommerce", link: "/resources/commerce" },
            
            { name: t("menu.report"), id: "report", link: "/resources/report" },

        ] },
        // { name: t("menu.documenthec"), id: "resources", link: "/resources" },
        { name: t("menu.Contacts"), id: "contacts", link: "/contacts" },
    ]

    const tabIndex = () => {
        let founded = -1;
        for (let i = 0; i < routes.length; i++) {
            if (window.location.pathname.includes(routes[i].id)) {
                founded = i;
            }
        }
        return founded;
    }

    const tabs = (
        <Tabs value={tabIndex()} textColor='secondary' indicatorColor='secondary' sx={{
            mr: 'auto', width: '100%', "& .Mui-selected": { color: 'secondary !important' },
            "& .css-1xkultq-MuiButtonBase-root-MuiTab-root.Mui-selected": { color: 'secondary !immportant' },
            "& .css-1pyd7p9-MuiButtonBase-root-MuiTab-root.Mui-selected": { color: 'secondary !immportant' }
        }}>
            {
                routes.map((route, i) => (
                    <Tab
                        aria-owns={subOpen ? 'mouse-over-popover' : undefined}
                        aria-haspopup={route.sub_menu && "true"}
                        onMouseEnter={route.sub_menu ? (event) => handlePopoverOpen(event, i) : undefined}
                        component={!route.sub_menu ? Link : undefined}
                        to={!route.sub_menu ? route.link : undefined}
                        style={{ textTransform: 'none' }}
                        label={route.name}
                        key={i}
                        sx={{ fontWeight: '600', mr: 'auto', fontSize: '1rem', color: tabIndex() === i ? 'secondary' : '#fff' }}
                        icon={route.sub_menu && <Arrow />}
                        iconPosition={route.sub_menu && 'end'}
                    />
                ))
            }
            <Popover
                id="mouse-over-popover"
                sx={{ pointerEvents: 'none', p: '2rem' }}
                open={subOpen}
                anchorEl={sub}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <div style={{ padding: '0.5rem 0.2rem', width: '10rem', pointerEvents: "auto", backgroundColor: '#157200' }}>
                    {
                        routes[parentMenu]?.sub_menu?.map((sub, index) => {
                            const subs = (routes[parentMenu].sub_menu).length
                            let active = -1
                            for (let i = 0; i < subs; i++) {
                                if ((window.location.pathname).includes(routes[parentMenu].sub_menu[i].id)) {
                                    active = i
                                }
                            }

                            return (
                                <div key={index}>
                                    <Button onClick={() => navigate(sub.link)} sx={[{
                                        mr: '1rem', display: 'block', borderRadius: '0', textTransform: 'none', width: '100%',
                                        color: index === active ? "#000" : "#fff", backgroundColor: index === active ? "#FDE50D" : "#157200", fontWeight: "600"
                                    },
                                    { "&:hover": { color: '#407b33', bgcolor: '#e0f1db' } }]} variant='text'>
                                        {sub.name}
                                    </Button>
                                    {index < subs - 1 && <Divider sx={{ color: '#fff', bgcolor: '#fff' }} />}
                                </div>
                            )
                        }
                        )}
                </div>
            </Popover>
        </Tabs>
    );

    const drawer = (
        <SwipeableDrawer
            open={openDrawer}
            onOpen={() => { handleMobilePopoverClose(); setOpenDrawer(true) }}
            onClose={() => setOpenDrawer(false)}
            sx={{ p: 0 }} >
            <List sx={{ p: 0 }} disablePadding>
                {routes.map((route, index) => {
                    return (
                        <ListItem key={index}>
                            {route.sub_menu ? (
                                <Accordion sx={{ width: '100%', px: 0, boxShadow: 0, }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMore />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                        sx={{ bgcolor: tabIndex() === index ? '#157200' : '#fff', color: tabIndex() === index ? '#fff' : '#000' }}
                                    >
                                        {route.name}
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ bgcolor: tabIndex() === index ? '#157200' : '#fff', px: 0.5, py: 0 }}>
                                        <List>
                                            {route.sub_menu.map((sub, i) => {
                                                let active = -1
                                                if ((window.location.pathname).includes(sub.id)) {
                                                    active = i
                                                }
                                                return (
                                                    <Box key={i}>
                                                        <ListItem sx={{ px: 0, bgcolor: tabIndex() === index ? active === i ? "#FDE50D" : "#157200" : "#fff", color: tabIndex() === index ? active === i ? "#000" : "#fff" : "#000" }} >
                                                            <ListItemButton onClick={() => navigate(sub.link)} sx={{ pl: 2 }}>
                                                                {sub.name}
                                                            </ListItemButton>
                                                        </ListItem>
                                                        {i < route.sub_menu.length - 1 && <Divider />}
                                                    </Box>
                                                )
                                            })}
                                        </List>
                                    </AccordionDetails>
                                </Accordion>

                            ) : (
                                <ListItemButton sx={{
                                    bgcolor: tabIndex() === index ? '#157200' : '#fff'
                                }}
                                    aria-owns={mobileSubOpen ? 'mobile-popover' : undefined}
                                    aria-haspopup={route.sub_menu && "true"}
                                    onClick={route.sub_menu === null ? () => navigate(route.link) : handleMobilePopover}
                                >
                                    <ListItemText sx={{ color: tabIndex() === index ? '#fff' : '#000' }} primary={route.name} />
                                    {
                                        route.sub_menu && (
                                            <ListItemIcon sx={{ color: tabIndex() === index ? '#fff' : '#000' }}>
                                                <Arrow />
                                            </ListItemIcon>)
                                    }
                                </ListItemButton>
                            )}



                            <Popover
                                id="mobile-popover"
                                sx={{ pointerEvents: 'none', bgcolor: '#157200' }}
                                open={mobileSubOpen}
                                anchorEl={mobileSub}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                                onClose={handleMobilePopoverClose}
                                disableRestoreFocus >
                                {(
                                    routes[1].sub_menu).map((sub, i) => {
                                        const mobileSubs = (routes[1].sub_menu).length
                                        let active
                                        if ((window.location.pathname).includes('commerce')) {
                                            active = 0
                                        } else if ((window.location.pathname).includes('transport')) {
                                            active = 1
                                        } else {
                                            active = -1
                                        }
                                        return (
                                            <div key={i} style={{ px: '1rem', bgcolor: '#157200', py: '2rem' }}>
                                                <Button onClick={() => navigate(sub.link)} sx={[{
                                                    display: 'block', borderRadius: '0', textTransform: 'none', pointerEvents: "auto",
                                                    width: '100%', color: i === active ? "#fff" : "#157200", backgroundColor: i === active ? "#157200" : "#fff",
                                                    fontWeight: "600"
                                                }, { "&:hover": { color: '#407b33' } }]} variant='text'>
                                                    {sub.name}
                                                </Button>
                                                {i < mobileSubs - 1 && <Divider />}
                                            </div>
                                        )
                                    }
                                    )}
                            </Popover>
                        </ListItem>
                    )
                })}
            </List>
        </SwipeableDrawer>
    );

    const setAppLang = () => {
        if (localStorage.getItem('lang')) {
            i18n.changeLanguage(localStorage.getItem('lang'));
        } else {
            USER_LANG in APP_LANG ? localStorage.setItem('lang', APP_LANG[USER_LANG]) : localStorage.setItem('lang', 'fr');
        }
    }

    useEffect(() => {
        const getVisitorIp = async () => {
            try {
                const response = await fetch('https://api.ipify.org');
                const data = await response.text();
                setIpAdress(data)
            } catch (err) { }
        }

        const fetchIpInfo = async () => {
            try {
                const response = await fetch(`https://geolocation-db.com/json/345dd3d0-b984-11ee-af01-4784ec48a61d/${ipAdress}`);
                const data = await response.json();
                setGeoInfo(data)
            } catch (err) { }
        }

        const getCountryNumber = () => {
            geoInfo.country_code in COUNTRY_NUMBER ? setCountryNumber(COUNTRY_NUMBER[geoInfo.country_code]) : setCountryNumber(-1);
        }

        setAppLang();
        getVisitorIp();
        if (ipAdress !== '') fetchIpInfo()
        if (geoInfo.length !== 0) getCountryNumber()
    }, [ipAdress, geoInfo])

    return (
        <Grid container direction="column" justifyContent="space-between" alignItems="center">
            <Grid container direction="row" sx={{
                px: { xs: '1rem', sm: '2rem', md: '5rem', lg: '10rem' }, py: '0.1rem', borderBottom: '2px solid', borderBottomColor: '#FDF50D', backgroundColor: "#157200"
            }}
                justifyContent="space-between" alignContent='center' alignItems="center">
                <Grid container item xs={12} sm={12} md={4} lg={3} alignContent='center' alignItems='center' justifyContent="space-between" direction="row">
                    <Grid item>
                        <Button href='https://agrhymet.cilss.int/' variant='text' sx={{ color: '#fff' }}>AGRHYMET CCR-AOS</Button>
                    </Grid>
                    <Grid item sx={{ color: "#fff" }}>
                        |
                    </Grid>
                    <Grid item>
                        <Button href='https://www.cilss.int/' variant='text' sx={{ color: '#fff' }}>CILSS</Button>
                    </Grid>
                </Grid>
                <Grid item>
                    <Button
                        aria-describedby={id}
                        sx={{ color: '#fff' }}
                        onClick={handleClick}
                        variant="text" >
                        {t(`lang.lang_${i18n.language}`)}
                        <Arrow />
                    </Button>

                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} >
                        <Grid container direction="column">

                            <Grid item>
                                <Button sx={{
                                    width: '100%', color: "#157200", fontWeight: "600"
                                }} disabled={i18n.language === 'fr'}
                                    variant={i18n.language === 'fr' ? 'contained' : 'text'}
                                    onClick={() => handleChangeLang('fr')} >
                                    Français
                                </Button>
                            </Grid>

                            <Grid item>
                                <Button sx={{
                                    width: '100%', color: "#157200", fontWeight: "600"
                                }} disabled={i18n.language === 'en'}
                                    variant={i18n.language === 'en' ? 'contained' : 'text'}
                                    onClick={() => handleChangeLang('en')} >
                                    English
                                </Button>
                            </Grid>
                        </Grid>
                    </Popover>
                </Grid>
            </Grid>

            <Grid container item 
            // sx={{
            //     px: {
            //         xs: '0.5rem', sm: '1rem', md: '2.5rem', lg: '5rem', backgroundImage: `url(${Baner})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'
            //     }
            // }}
                alignItems="center"
                alignContent='center'
                direction={{ xs: 'column', sm: "row" }} justifyContent="space-between">
                <Grid item spacing={2} xs={12} md={2} alignContent="center" alignItems="center" sm={5} lg={2} container>
                    <Grid xs={6} item >
                        <Box component={Link} to="/" variant="div" display='flex' justifyContent="space-between" flexDirection="column" sx={{ textDecoration: 'none', width: "150px" }} alignItems="center" alignContent="center">
                            <img style={{ width: "70%" }} src={Logo} alt="Logo du projet" />
                        </Box>
                    </Grid>
                    <Grid xs={6} item>
                        <img style={{ width: "150px" }} src={LogoCilss} alt="Logo du CILSS" />
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={3} md={3} lg={3} sx={{ pl: '1rem' }}>
                    <marquee style={{ width: '18rem', marginBottom: '2rem', fontWeight: '600', textTransform: 'uppercase', PaddingLeft: '2rem' }}>
                        {t("header.Welcome to the platform for disseminating regional trade texts and sharing trade opportunities")}
                    </marquee>

                    {(countryNumber !== -1) && (
                        <Box sx={{ display: 'flex', alignItems: 'center', alignContent: 'center', mb: '1rem', bgcolor: '#007416', borderRadius: 50, width: '19rem' }}>
                            <Avatar sx={{ bgcolor: '#fff', width: 30, height: 30, zIndex: 2, ml: 1 }}>
                                <LocalPhoneRoundedIcon sx={{ color: '#007416' }} />
                            </Avatar>

                            <Button variant='text' sx={{ width: '100%', fontWeight: '600', textTransform: 'none', ml: -5, }} href={`tel:${countryNumber}`} >
                                <Typography sx={{ bgcolor: '#007416', color: '#fff', p: '0.3rem', pr: 2, borderRadius: 50, ml: 5, zIndex: 1 }}>
                                    {t('header.ivr_call')}
                                </Typography>

                                <Typography sx={{ bgcolor: '#fff', py: '0.2rem', pr: '0.4rem', ml: -2, pl: 3, borderRadius: 50, letterSpacing: 4, fontWeight: '600' }}>
                                    {countryNumber}
                                </Typography>
                            </Button>
                        </Box>
                    )}

                </Grid>

                <Grid item xs={12} sm={12} md={3} lg={3}>
                    <Box variant="div" display="flex" justifyContent="center" alignContent="center" alignItems="center" flexDirection="column">
                        <img src={mPhoto} style={{ width: '25%' }} alt='App mobile' />
                        <Button href="https://play.google.com/store/apps/details?id=com.cilss.app&hl=fr&gl=US" target='_blank' startIcon={<InstallMobile />}
                            variant='contained' sx={{ textTransform: 'none', textDecoration: 'none', fontWeight: '600', color: 'primary', borderRadius: 20 }} >
                            {t("header.download_mobile_app")}
                        </Button>
                    </Box>
                </Grid>

                <Grid item container xs={12} sm={12} md={12} lg={3} sx={{
                     paddingRight: "15px", backgroundImage: `url(${CilssCard})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center right', py: "2rem"
                }} alignItems="flex-end" direction='column'>
                        <Box display="flex" flexDirection={{ xs: "row" }} justifyContent="space-between" alignItems="center">
                                <img style={{width: "24%", height: "auto"}} src={Cedeao} alt="Logo du CEDEAO" />
                                <img style={{width: "24%", height: "auto"}} src={Uemoa} alt="Logo de l'UEMOA" />
                                <img style={{width: "24%", height: "auto"}} src={sd3c} alt="Logo de SD3C" />
                                <img style={{width: "24%", height: "auto"}} src={praps} alt="Logo du PRAPS2" />
                        </Box>
                </Grid>
            </Grid>

            <Grid container item alignItems='center' justifyContent='space-between' alignContent='center'
                sx={{ borderTopColor: '#FDF50D', borderBottomColor: '#157200', backgroundColor: '#fff' }} >
                <AppBar onMouseLeave={routes[1].sub_menu && handlePopoverClose} color='transparent' sx={{
                    borderBottom: 2,
                    borderTop: 2,
                    borderTopColor: '#FDF50D',
                    borderBottomColor: '#157200',
                    bgcolor: '#157200',
                    px: '10rem'
                }} elevation={0} position='sticky'>
                    <Container>
                        <Toolbar sx={{
                            bgcolor: 'primary'
                        }} disableGutters>
                            {matchesMD ? drawer : tabs}
                            {matchesMD && (
                                <IconButton onClick={() => setOpenDrawer(true)} >
                                    <MenuIcon fontSize='large' sx={{ color: '#fff' }} />
                                </IconButton>
                            )
                            }
                        </Toolbar>
                    </Container>
                </AppBar>
            </Grid>
        </Grid>
    )
}