import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Button, Card, Divider, Container, Box, IconButton, Tooltip } from "@mui/material";

import GavelIcon from '@mui/icons-material/Gavel';
import { useValue } from "../../../context/ContextProvider";
import axios from "../../../api/without_user_token";
import { ArrowForward } from "@mui/icons-material";

const synth = window.speechSynthesis

const Messages = ({ messages }) => {

    const { t } = useTranslation('global')

    const {
        dispatch
    } = useValue()

    const { i18n } = useTranslation('global')

    const [voices, setVoices] = useState([])

    const [actIndex, setActIndex] = useState(0)

    const [selectedLang, setSelectedLang] = useState(0)

    const handlechange = (event, newValue) => {
        setActIndex(newValue)
    }

    const populateVoiceList = useCallback(() => {
        const newVoices = synth.getVoices()
        setVoices(newVoices)
    }, [])

    useEffect(() => {
        populateVoiceList()
        if (synth.onvoiceschanged !== undefined) {
            synth.onvoiceschanged = populateVoiceList;
        }
    }, [populateVoiceList])

    const currentVoices = i18n.language === 'fr' ? (voices.filter(voice => voice.lang === "fr-FR")) : (voices.filter(voice => voice.lang === "en-US"))

    const speak = async (text) => {

        const synth = window.speechSynthesis

        var utterance = new SpeechSynthesisUtterance(text)
        utterance.voice = currentVoices[selectedLang]
        synth.speak(utterance)
    }

    const concatTexte = (texts) => {
        let block = ""
        texts.forEach(text => {
            block = block + "" + text.speech
        })
        return block
    }

    const updateReadCount = (event, uuid) => {
        dispatch({ type: "START_LOADING" })
        axios.get('read_sub_domain', {
            params: { uuid: uuid },
            headers: { 'Content-Type': 'application/json' }
        }).then((response) => {

        })
            .catch((err) => {
                console.log(err.message)
            })
        dispatch({ type: "END_LOADING" })
    }

    const handleActorClick = (event, i, uuid) => {
        setActIndex(i)
        updateReadCount(event, uuid)
    }
    return (
        <Grid sx={{ px: { xs: '1rem', sm: '2rem', md: '5rem', lg: '10rem' }, py: '2rem' }} container direction='row'>
            <Container>
                <Grid item container alignContent='flex-start' direction='row'>
                    <Grid item gap={1} container sx={{ gridAutoFlow: "column", gridTemplateColumns: "repeat(auto-fill,minmax(60px,1fr)) !important", gridAutoColumns: "minmax(60px, 1fr)" }}>
                        {messages.map((message, i) => (
                            <Grid item key={i}>
                                <Button sx={{
                                    backgroundColor: i === actIndex ? "#2a7319" : '#d8f2d2',
                                    color: i === actIndex ? '#fff' : '#2a7319',
                                    textTransform: 'none',
                                    fontWeight: '600', "&:hover": {
                                        bgcolor: '#2a7319', color: '#fff'
                                    }
                                }}
                                    onClick={(event) => handleActorClick(event, i, message.actor_uuid)}>
                                    {message.actor}
                                </Button>
                            </Grid>
                        ))}
                    </Grid>
                    <Grid item sx={{ my: '1.5rem' }}>
                        <Card elevation={5} sx={{}}>
                            <Grid item container >
                                <Grid item container justifyContent='flex-start' direction='row'>
                                    <Grid item sx={{ p: '1rem' }}>
                                        <GavelIcon sx={{ color: '#157200' }} />
                                    </Grid>
                                </Grid>
                                <Grid item container sx={{ pt: '1rem', px: '1rem', mb: '-1rem' }} justifyContent='space-between'>
                                    <Grid item>
                                        <Divider />
                                    </Grid>
                                </Grid>
                                <Card sx={{ overflow: 'scroll', maxHeight: '30rem' }}>
                                    <Grid item sx={{ pt: '1rem' }}>
                                        {(messages[actIndex].textes).map((texte, i) => (
                                            <Box sx={{ backgroundColor: i % 2 === 0 ? '#C1DCBB' : '#fff' }} key={texte} justifyContent="space-between" display='flex' flexDirection="row">
                                                <Typography sx={{ textDecoration: 'none', listStyleType: 'none', listStylePosition: 'inside', lineHeight: '2rem', my: '0.5rem', px: '1rem', py: '0.5rem', textAlign: 'justify' }} key={texte.texte}> {texte.texte}</Typography>
                                                {(texte.link !== null && texte.link !== "None") && (
                                                    <Tooltip title={t("origin_text")}>
                                                        <IconButton href={texte.link}>
                                                            <ArrowForward />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                            </Box>
                                        ))}
                                    </Grid>
                                </Card>
                            </Grid>
                            <Grid item container justifyContent='flex-end' direction='row'>
                                <Grid item sx={{ p: '0.5rem' }}>
                                    <GavelIcon sx={{ color: '#157200' }} />
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </Grid>

    )
}
export default Messages