import React, { useState } from "react";
import { Box, Button, Card, CardActions, CardContent, CircularProgress, Grid, Typography, alpha, } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ArrowForward } from "@mui/icons-material";



const Top = ({ messages }) => {

    const { t } = useTranslation('global')


    const [messageIndex, setMessageIndex] = useState(0)
    const [actIndex, setActIndex] = useState(0)


    return (
        <Grid container direction='column' sx={{ px: { xs: '1rem', sm: '2rem', md: '5rem', lg: '10rem' }, py: '2rem', bgcolor: 'rgb(245 245 245)' }}>
            <Grid item sx={{ my: 2 }}>
                <Typography sx={{ fontWeight: '700', fontSize: '1.875rem', textAlign: 'center' }}>{t('top.Top 3 most viewed key messages')}</Typography>
            </Grid>
            <Grid item gap={1} container direction={{ xs: 'column', sm: 'row' }} alignItems='center' alignContent='center' justifyContent='flex-end'>
                <Grid item container sx={{ backgroundImage: `url(${messages[messageIndex].img})`, backgroundSize: 'cover', backgroundPosition: messageIndex === 0 ? '100% 50%' : "center", width: { xs: '100%', sm: '100%', md: '30%', lg: '31%' }, pt: { md: '5rem', sm: '0' }, px: { md: '0', xs: '10rem', sm: '20rem', lg: '0' }, py: { sm: '0', md: '14rem', lg: '14rem' }, backgroundColor: alpha("#000", 0.9) }} alignItems='flex-end' alignContent='flex-end' direction='column'>
                    {messages.length === 0 ? (<CircularProgress />) : messages?.map((message, i) => (
                        <Button key={i} startIcon={message.icon} onClick={() => setMessageIndex(i)}
                            sx={{
                                backgroundColor: messageIndex === i ? '#2a7319' : '#d8f2d2',
                                color: messageIndex === i ? '#fff' : "#2a7319",
                                borderRadius: '0',
                                fontWeight: '600', width: '12rem',
                                textTransform: 'none',
                                "&:hover": {
                                    bgcolor: '#2a7319',
                                    color: '#fff'
                                }
                            }}>
                            {message.name}
                        </Button>
                    ))}
                </Grid>
                <Grid item container sx={{ py: '0.5rem', px: '0.6rem' }} xs={12} sm={12} md={8} lg={8} direction='column'>

                    <Card sx={{ p: '0.5rem' }} elevation={5}>
                        <Grid item gap={1} container sx={{ gridAutoFlow: "column", gridTemplateColumns: "repeat(auto-fill,minmax(60px,1fr)) !important", gridAutoColumns: "minmax(60px, 1fr)" }}>
                            {messages.length === 0 ? (<CircularProgress />) : (messages[messageIndex].messages).map((message, acIndex) => (
                                <Grid item key={acIndex}>
                                    <Button sx={{
                                        backgroundColor: acIndex === actIndex ? "#2a7319" : '#d8f2d2', color: acIndex === actIndex ? '#fff' : '#2a7319',
                                        textTransform: 'none', fontWeight: '600', "&:hover": { bgcolor: '#2a7319', color: '#fff' }
                                    }} onClick={() => setActIndex(acIndex)}>
                                        {message.actor}
                                    </Button>
                                </Grid>
                            ))}
                        </Grid>
                        <Grid item sx={{ mt: '0.5rem', }}>
                            <Card variant="outlined" sx={{ px: '1rem', py: '1rem' }}>
                                <CardContent sx={{ height: "22rem", overflow: 'scroll', }}>
                                    {(messages[messageIndex].messages[actIndex].texte.split(".")).map((text, i) => (
                                        <Typography sx={{ textDecoration: 'none', listStyleType: 'none', listStylePosition: 'inside', lineHeight: '2rem', backgroundColor: i % 2 === 0 ? '#C1DCBB' : '#fff', my: '0.5rem', px: '1.2rem', py: '0.5rem', textAlign: 'justify' }} key={i} > {text + "."} </Typography>
                                    ))}
                                </CardContent>
                                {(messages[messageIndex].messages[actIndex].link !== null && messages[messageIndex].messages[actIndex].link !== "None") && (
                                    <CardActions>
                                        <Box display="flex" flexDirection="row" alignContent="flex-end" alignItems="flex-end" justifyContent="flex-end">
                                            <Button size="small" sx={{ textTransform: "none" }} href={messages[messageIndex].messages[actIndex].link} endIcon={<ArrowForward />} variant="contained">Consulter le texte dans son format initial</Button>
                                        </Box>
                                    </CardActions>
                                )}
                            </Card>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
        </Grid>
    )
}
export default Top