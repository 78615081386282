/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  Box,
  CircularProgress,
  Grid,
  Link,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  Tabs,
  Tab,
  Paper,
  Container,
  TextField,
  Pagination,
  Tooltip,
  alpha
} from '@mui/material';
import Layout from '../components/ui/layout';
import Baner from '../components/ui/baner';
import DocumentImage from '../images/documentheque.jpeg'; 
import axios from '../api/without_user_token';
import { useTranslation } from 'react-i18next';
import { Download } from '@mui/icons-material';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Doccommerce = () => {
  const [documents, setDocuments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(0);
  const [categories, setCategories] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const { t, i18n } = useTranslation('global')
  const documentsPerPage = 20;
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  }

  useEffect(() => {
    document.title = t('docs.page_ttl');
  },);

  useEffect(() => {
    const fetchDocuments = async () => {
      setIsLoading(true)
      try {
        const response = await axios.get('get_document_by_type_lang', {
          params : { lang: i18n.language, 'type_file': t("COMMERCE") },
          headers: { 'Authorization': `Bearer` } 
        });
        console.log('resp', response);
        const allDocuments = Object.values(response.data).flat();
        const byLang = allDocuments.filter(doc => doc.lang === i18n.language)
        setDocuments(byLang);
        const uniqueCategories = [t('all_f'), ...new Set(byLang.map(doc => doc.type_file))];
        setCategories(uniqueCategories);
      } catch (error) {
        console.error('Erreur lors de la récupération des documents:', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchDocuments();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setCurrentPage(1);
  };

  const filteredDocuments = documents.filter(doc =>
    (activeTab === 0 || doc.type_file === categories[activeTab]) &&
    doc.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const indexOfLastDocument = currentPage * documentsPerPage;
  const indexOfFirstDocument = indexOfLastDocument - documentsPerPage;
  const currentDocuments = filteredDocuments.slice(indexOfFirstDocument, indexOfLastDocument);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };


  return (
    <Layout>
      <Baner title={t('docs.ttl3')} subTitle={t('docs.sub_ttl')} />
      <Container sx={{ mt: 4 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={3}>
            <Box sx={{ textAlign: 'center' }}>
              <img src={DocumentImage} alt="Documents" style={{ maxWidth: '100%', borderRadius: '8px' }} />
            </Box>
          </Grid>
          <Grid item xs={12} md={9}>
            <TextField
              fullWidth
              label={t('docs.docs_srch')}
              variant="outlined"
              onChange={(e) => setSearchQuery(e.target.value)}
              sx={{ mb: 2 }}
            />
            {/* <Paper square sx={{ mb: 2 }}>
              <Tabs
                value={activeTab}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="auto"
              >
                {categories.map((category, index) => (
                  <Tab key={index} label={index === 0 ? category : t(`admin.type_${category.toLowerCase()}`)} />
                ))}
              </Tabs>
            </Paper> */}
            {isLoading ? (
              <CircularProgress />
            ) : (
              <>
                <Grid container spacing={2}>
                  {currentDocuments.map((document) => (

                    <Grid item xs = { 12} sm = { 6} md = { 4} key = { document.uuid } >
                    <Card sx={{ borderBottom: '6px solid green', py: 0, px: 0 }}>
                      <CardContent sx={{ py: 0, px: 0 }}>
                        <Card variant="outlined" sx={{ height: 100, overflow: 'hidden', maxWidth: {sm: '100%', xs: 300, md: '100%', lg: '100%'}}}>
                          <Document file={document.path} onLoadSuccess={onDocumentLoadSuccess}>
                            <Page pageNumber={pageNumber} />
                          </Document>
                        </Card>
                        <Box sx={{ bgcolor: alpha('#fff', 0.9), zIndex: 2, px: 1 }}>
                          <Typography gutterBottom sx={{ height: 50, pt: 1, fontSize: '1rem' }} variant="body1">
                            {document.title.length > 40 ? (<Tooltip title={document.title.charAt(0).toUpperCase()
                              + document.title.slice(1).toLowerCase()}>
                              {`${document.title.charAt(0).toUpperCase()
                                + document.title.slice(1).toLowerCase().substring(0, 40)}...`}
                            </Tooltip>) : (document.title.charAt(0).toUpperCase()
                              + document.title.slice(1).toLowerCase())}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            {`${t('docs.docs_cat')}: `} {t(`admin.type_${document.type_file.toLowerCase()}`)}
                          </Typography>
                        </Box>
                      </CardContent>
                      <CardActions sx={{ justifyContent: 'flex-end' }}>
                        <Button sx={{ py: 0, textTransform: 'none' }} startIcon={<Download />} size="small" color="primary" component={Link} href={document.path} target="_blank">
                          {t('docs.docs_dwnld')}
                        </Button>
                      </CardActions>
                    </Card>
              </Grid>
                  ))}
              </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3, mb:3 }}>
              <Pagination
                count={Math.ceil(filteredDocuments.length / documentsPerPage)}
                page={currentPage}
                onChange={handleChangePage}
                color="primary"
              />
            </Box>
          </>
            )}
        </Grid>
      </Grid>
    </Container>
    </Layout >
  );
};

export default Doccommerce;
