import { Container, Grid, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const Baner = () => {
    const { t } = useTranslation('global')

    return (
        <Grid container sx={{ px: { xs: '1rem', sm: '2rem', md: '5rem', lg: '10rem' }, py: '3rem', backgroundColor: '#157200' }} justifyContent='center' direction='column'>
            <Container>
                <Grid item sx={{ mb: '-1rem' }}>
                    <Typography sx={{ fontSize: '1.375rem', color: '#fff' }}> {t("Key messages about")} </Typography>
                </Grid>
                <Grid item>
                    <Typography sx={{ fontSize: '3.125rem', fontWeight: '700', color: '#fff' }}> {t('Trade')} </Typography>
                </Grid>
            </Container>
        </Grid>
    )
}

export default Baner