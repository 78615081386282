import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import AdminLayout from './../../components/ui/admin_layout';
import Chiffres from "../../components/ui/dashboard/chiffres";
import Message from "../../images/icons/key_message.png"
import Actor from "../../images/icons/actor.png"
import Users from "../../images/icons/ic_glass_users.png"
import CircularChart from "../../components/ui/dashboard/circular_chart";
import { useValue } from "../../context/ContextProvider";
import Grid from '@mui/material/Unstable_Grid2';
import axios from "axios";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import useCheckActive from "../../hooks/useCheckActive";
import useCheckConnexionDuration from "../../hooks/useCheckConnexionDuration";

const Dashboard = () => {
    useCheckConnexionDuration()
    useCheckActive()
    const { dispatch }      = useValue();
    const [data, setData]   = useState([]);
    let chiffres            = [];
    let domaine_chiffres    = [];
    let app_download_count  = [];
    let serie_data          = [];
    const {t}         = useTranslation("global");
    
    useEffect(() => {
        const getDashboardData = () => {
            dispatch({type: 'START_LOADING'})
            axios.get('get_all_item_count', { headers: { 'Content-Type': 'application/json' } })
            .then((response) => {
                if (response.status === 200) {
                    setData(response.data)
                }
            }).catch((err) => {
                dispatch({
                    type: 'UPDATE_ALERT',
                    payload: {
                        open: true,
                        severity: 'error',
                        message: err.message
                    }
                })
            })
            dispatch({type: 'END_LOADING'})
        }
        getDashboardData();
    }, [dispatch]);

    if (data.length !== 0) {
        const {data_text_count, sub_domain_count, user_count_count, domaines, mobile} = data;
        chiffres.push(
            {title: t("admin.key_message"), total: data_text_count, color: "primary", icon: <img alt="icon" src={Message} />},
            {title: t("admin.actor_target"), total: sub_domain_count, color: "warning", icon: <img alt="icon" src={Actor} />},
            {title: t("admin.users"), total: user_count_count, color: "success", icon: <img alt="icon" src={Users} />}
        );
        domaine_chiffres    = domaines;
        app_download_count  = mobile.download_count;
        let country_data    = mobile.country_count;
        serie_data.push({
            name    : t("admin.download"),
            type    : 'column',
            fill    : 'solid',
            data    : app_download_count
        });

        for (const key in country_data) {
            serie_data.push({
                name    : key,
                type    : 'line',
                data    : country_data[key]
            });
        }
    }

    return(
        <>
        <AdminLayout>
            <Grid container sx={{py: 2}} direction='column'>
                <Typography variant="h6" sx={{ mb: 5 }}>
                    { t("admin.salutation") }
                </Typography>

                {
                    data.length === 0 ? (<div style={{padding: "2rem 10rem"}}> <CircularProgress /> </div>) : 
                    <>
                        <Grid container spacing={2} justifyContent='space-between'>
                            {chiffres.map(chiffre => (
                                <Grid key={chiffre.title} xs={12} sm={6} md={6} lg={4} >
                                    <Chiffres
                                        title={chiffre.title}
                                        total={chiffre.total}
                                        color={chiffre.color}
                                        icon={chiffre.icon}
                                    />
                                </Grid>
                            ))}
                        </Grid>

                        <Grid container sx={{pt: 2}} alignItems='flex-end' spacing={0.5} >

                            {(domaine_chiffres !== null && domaine_chiffres?.length !==0) && (<Grid xs={12} >
                                <CircularChart
                                    title={t("admin.pie_chart_title")}
                                    chart= {{ series: domaine_chiffres }}
                                />
                            </Grid>)}
                        </Grid>
                    </>
                }
            </Grid>
            </AdminLayout>
        </>
    )
}
export default Dashboard