import axios from "../../api/axios"

export const getNotifs = async(currentUser, dispatch)=>{
    try{
        const response = await axios.get("count_unvalidate_data_text", {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${currentUser.token}`
            }
        })
        if(response){
            dispatch({type: "UPDATE_NOTIF", payload: response.data})
        }
    } catch(err){
        dispatch({
            type: 'UPDATE_ALERT',
            payload: {
                open: true,
                severity: 'error',
                message: err.message
            }
        })
        console.log(err.message)
    }
}