import React, { useEffect, useMemo, useState } from "react";
import { Typography, Box, IconButton, Grid, Tab, Tabs, Button, Dialog, DialogTitle, DialogContent, TextField, DialogActions, Fab, Select, Chip, Paper } from "@mui/material";
import AdminLayout from "../../components/ui/admin_layout";
import { DataGrid } from "@mui/x-data-grid";
import { Delete, Edit, Add, Close, PublishedWithChanges, Done, Clear, Save } from "@mui/icons-material";
import axios from "axios";
import { useTranslation } from "react-i18next";
import useCheckConnexionDuration from "../../hooks/useCheckConnexionDuration";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { CircularProgress } from "@mui/material";
import { useValue } from "../../context/ContextProvider";
import useCheckActive from "../../hooks/useCheckActive";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import isEditor from "../../utils/is-editor";
import { getNotifs } from "../../actions/utils/getNotifs";

const Commerce = () => {
    useCheckConnexionDuration()
    useCheckActive()
    const { state: { currentUser }, dispatch } = useValue()
    const { t, i18n } = useTranslation('global');
    const [open, setOpen] = useState(false)
    const [openEdit, setOpenEdit] = useState(false)
    const [data, setData] = useState([]);
    const [pageSize, setPageSize] = useState(10)
    const [resume, setResume] = useState('');
    const [init, setInit] = useState('')
    const [link, setLink] = useState('')
    const [lang, setLang] = useState(i18n.language);
    const [langAdd, setLangAdd] = useState(i18n.language);
    const [subDomainId, setSubDomainId] = useState(0);
    const [selectedRow, setSelectedRow] = useState(null)
    const [openValidate, setOpenValidate] = useState(false)
    const [textId, setTextId] = useState(0);
    let boites = [];
    let message = [];
    const [activeActor, setActiveActor] = useState(0);

    const handlechange = (event, newValue) => {
        setActiveActor(newValue)
    }

    const getDataText = () => {
        dispatch({ type: 'START_LOADING' })
        axios.get('get_all_data_text_by_domaine_name', {
            params: { name: 'Commerce' },
            headers: { 'Content-Type': 'application/json' }
        }).then((response) => {
            if (response.status === 200) {
                setData(response.data)
                console.log(response.data)
            }
        }).catch((err) => {
            dispatch({
                type: 'UPDATE_ALERT',
                payload: {
                    open: true,
                    severity: 'error',
                    message: err.message
                }
            })
        })
        dispatch({ type: 'END_LOADING' })
    }

    const addDataText = () => {
        dispatch({ type: 'START_LOADING' })
        let user_id = JSON.parse(localStorage.getItem('currentUser')) !== null ? JSON.parse(localStorage.getItem('currentUser')).userId : null
        let data = {
            lang: langAdd,
            user_id: user_id,
            sub_domain_id: subDomainId,
            title: 'Message sur le commerce',
            resume: resume,
            text_content: init,
            text_content_initial: init,
            link: link
        }
        const config = { headers: { 'Content-Type': 'application/json' } };

        axios.post('add_data_text', data, config).then((response) => {
            if (response.status === 200) {
                getDataText();
                setOpen(false);
                setResume('');
                setInit('')
                setLink('')
                getNotifs(currentUser, dispatch)
            }
        }).catch((err) => {
            dispatch({
                type: 'UPDATE_ALERT',
                payload: {
                    open: true,
                    severity: 'error',
                    message: err.message
                }
            })
        })
        dispatch({ type: 'END_LOADING' })
    }

    const updateDataText = () => {
        dispatch({ type: 'START_LOADING' })
        let data = {
            lang: langAdd,
            uuid: textId,
            title: 'Message sur le commerce',
            resume: resume,
            text_content: init,
            text_content_initial: init,
            link: link

        }
        const config = { headers: { 'Content-Type': 'application/json' } };

        axios.post('update_data_text', data, config).then((response) => {
            if (response.status === 200) {
                getDataText();
                setOpenEdit(false);
                setResume('');
                setInit('')
                setSubDomainId('');
                setLink('');
                getNotifs(currentUser, dispatch)
                dispatch({
                    type: 'UPDATE_ALERT',
                    payload: {
                        open: true,
                        severity: 'success',
                        message: "Edited successfully"
                    }
                })
            }
        }).catch((err) => {
            dispatch({
                type: 'UPDATE_ALERT',
                payload: {
                    open: true,
                    severity: 'error',
                    message: err.message
                }
            })
        })
        dispatch({ type: 'END_LOADING' })
    }

    const handleRowEdit = (even, params) => {
        setOpenEdit(true);
        setResume(params.resume);
        setInit(params.init_text);
        setTextId(params.uuid);
        setSubDomainId(params.sub_domain_id);
        setLangAdd(params.lang);
        setLink(params.link);
    }

    const handleValidate = (params) => {
        setOpenValidate(true)
        setSelectedRow(params.row)
    }

    const validateText = () => {
        dispatch({ type: 'START_LOADING' })
        if (selectedRow) {
            let data = {
                uuid: selectedRow.uuid,
                user_id: currentUser.userId,
                status: "VALIDATE"

            }
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${currentUser.token}`
                }
            };
            axios.post('validate_data_text', data, config).then((response) => {
                if (response.status === 200) {
                    getDataText()
                    setOpenValidate(false)
                    getNotifs(currentUser, dispatch)
                    dispatch({
                        type: 'UPDATE_ALERT',
                        payload: {
                            open: true,
                            severity: 'success',
                            message: "Validé avec succès"
                        }
                    })
                }
            }).catch((err) => {
                dispatch({
                    type: 'UPDATE_ALERT',
                    payload: {
                        open: true,
                        severity: 'error',
                        message: err.message
                    }
                })
            })
        }
        dispatch({ type: 'END_LOADING' })
    }

    const rejectText = () => {
        dispatch({ type: 'START_LOADING' })
        if (selectedRow) {
            let data = {
                uuid: selectedRow.uuid,
                user_id: currentUser.userId,
                status: "REJECT"

            }
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${currentUser.token}`
                }
            };
            axios.post('validate_data_text', data, config).then((response) => {
                if (response.status === 200) {
                    getDataText()
                    setOpenValidate(false)
                    getNotifs(currentUser, dispatch)
                    dispatch({
                        type: 'UPDATE_ALERT',
                        payload: {
                            open: true,
                            severity: 'success',
                            message: "Rejeté avec succès"
                        }
                    })
                }
            }).catch((err) => {
                dispatch({
                    type: 'UPDATE_ALERT',
                    payload: {
                        open: true,
                        severity: 'error',
                        message: err.message
                    }
                })
            })
        }
        dispatch({ type: 'END_LOADING' })
    }

    const handleRowDelete = (event, uuid) => {
        dispatch({ type: 'START_LOADING' })
        axios.post('delete_data_text', { uuid: uuid }).then((response) => {
            if (response.status === 200) {
                getDataText();
                getNotifs(currentUser, dispatch)
            }
        }).catch((err) => {
            dispatch({
                type: 'UPDATE_ALERT',
                payload: {
                    open: true,
                    severity: 'error',
                    message: err.message
                }
            })
        })
        dispatch({ type: 'END_LOADING' })
    }

    const handleCloseEdit = () => {
        setOpenEdit(false);
        setResume('');
        setInit('');
        setSubDomainId('');
        setTextId('');
        setLink('');
    }

    const getColor = (status) => {
        if (status === 'PENDING') {
            return "warning"
        }
        if (status === 'VALIDATE') {
            return "success"
        }
        if (status === 'REJECT') {
            return "error"
        }
    }

    const columns = useMemo(() => [
        { field: "resume", headerName: t("admin.resume_data_text"), width: 400 },
        { field: "init_text", headerName: t("admin.init_text"), width: 400 },
        { field: "lang", headerName: t("admin.language"), width: 100 },
        { field: "created_by", headerName: t("admin.tb_crtd_by"), width: 100 },
        {
            field: "status", headerName: t("admin.text_status"), width: 150,
            renderCell: (params) => {
                return (
                    <Chip label={t(`admin.${params.row.status}`)} color={getColor(params.row.status)} variant="outlined" />
                )
            }
        },
        { field: "validated_by", headerName: t("admin.tb_vldtd_by"), width: 100 },
        {
            field: "actions", type: 'action', headerName: "Actions", width: 200,
            renderCell: (params) => (
                isEditor(currentUser) && (<Box sx={{ m: 1 }}>
                    <Fab sx={{
                        width: 40,
                        height: 40,
                    }}
                        onClick={event => handleRowEdit(event, params.row)}
                    >
                        <Edit />
                    </Fab>
                    <Fab sx={{
                        width: 40,
                        height: 40,
                        ml: 2
                    }}
                        onClick={event => handleRowDelete(event, params.id)}
                    >
                        <Delete />
                    </Fab>
                    {(currentUser?.userRole === 'Admin' && params.row.status === "PENDING") && (
                        <Fab sx={{
                            width: 40,
                            height: 40,
                            ml: 2
                        }}
                            onClick={() => handleValidate(params)}
                        >
                            <PublishedWithChanges />
                        </Fab>
                    )}

                </Box>)
            )
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ], [t, currentUser])

    const formatData = (data) => {
        for (const item of data) {
            for (const iterator of item.sub_domain_name) {
                let data_text = [];
                if (iterator.data_text.length !== 0) {
                    for (let index = 0; index < iterator.data_text.length; index++) {
                        let text = {
                            uuid: iterator.data_text[index].uuid,
                            lang: iterator.data_text[index].lang,
                            read: iterator.data_text[index].resume,
                            speech: iterator.data_text[index].resume,
                            init_text: iterator.data_text[index].text_content_initial,
                            status: iterator.data_text[index].status,
                            created_by: iterator.data_text[index].created_by,
                            validated_by: iterator.data_text[index].validated_by,
                            created_at: iterator.data_text[index].created_at,
                            validated_at: iterator.data_text[index].validated_at
                        };
                        data_text.push(text);
                    }
                }
                message.push({
                    sub_domain_id: iterator.sub_domain_id,
                    uuid: iterator.uuid,
                    actor: iterator.name,
                    textes: data_text,
                });
            }
        }

        let messages = message[activeActor];
        for (const it of messages.textes) {
            boites.push({
                sub_domain_id: messages.sub_domain_id,
                uuid: it.uuid,
                resume: it.read,
                status: it.status,
                init_text: it.init_text,
                created_by: it.created_by,
                validated_by: it.validated_by,
                validated_at: it.validated_at,
                created_at: it.created_at,
                lang: it.lang
            });
        }
    }

    const handleChangeAdd = (event) => {
        setLangAdd(event.target.value);
    };

    const handleChangeLang = (event) => {
        setLang(event.target.value);
    };

    const handleOpenModal = (event) => {
        setOpen(true);
    };

    const handlesetSubDomainId = (event, value) => {
        setSubDomainId(value.sub_domain_id)
    };

    if (data.length !== 0) {
        try {
            formatData(data[lang]);
        } catch (error) {
            formatData(data['fr']);
            setLang('fr')
        }
    }

    useEffect(() => {
        getDataText()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AdminLayout>
            <Box sx={{ height: "100%", py: '1rem' }}>
                <Typography variant="h5" sx={{ py: 2, textAlign: 'center' }} component='h3' >
                    {t("admin.trade_manage")}
                </Typography>
                <Grid2 sx={{ bgcolor: '#fff', p: 2 }}>
                    <Grid container direction='row' justifyContent='space-between'>
                        <Grid item>
                            <FormControl sx={{ width: '10rem' }} fullWidth>
                                <InputLabel id="select-label">{t("admin.choose_language")}</InputLabel>
                                <Select
                                    labelId="select-label"
                                    id="select"
                                    value={lang}
                                    label={t("admin.choose_language")}
                                    onChange={handleChangeLang}
                                >
                                    <MenuItem value="ar"> {t('lang.lang_ar')} </MenuItem>
                                    <MenuItem value="en"> {t('lang.lang_en')} </MenuItem>
                                    <MenuItem value="fr"> {t('lang.lang_fr')} </MenuItem>
                                    <MenuItem value="pt"> {t('lang.lang_pt')} </MenuItem>
                                </Select>
                            </FormControl>

                            <Dialog open={openEdit} onClose={() => setOpenEdit(false)}>
                                <DialogTitle>
                                    <Typography> {t("admin.edit_data_text")} </Typography>

                                    <IconButton
                                        sx={{
                                            position: 'absolute',
                                            top: '0.5rem',
                                            right: '0.5rem',
                                            color: (theme) => theme.palette.grey[500]
                                        }}
                                        onClick={handleCloseEdit}
                                    >
                                        <Close />
                                    </IconButton>
                                </DialogTitle>

                                <form>
                                    <DialogContent sx={{ py: '2rem' }} dividers>
                                        <FormControl sx={{display: 'none'}} fullWidth>
                                            <InputLabel id="select-label">{t("admin.choose_language")}</InputLabel>
                                            <Select
                                                labelId="select-label"
                                                id="select"
                                                value={langAdd}
                                                label={t("admin.choose_language")}
                                                onChange={handleChangeAdd}
                                            >
                                                <MenuItem value="ar"> {t('lang.lang_ar')} </MenuItem>
                                                <MenuItem value="en"> {t('lang.lang_en')} </MenuItem>
                                                <MenuItem value="fr"> {t('lang.lang_fr')} </MenuItem>
                                                <MenuItem value="pt"> {t('lang.lang_pt')} </MenuItem>
                                            </Select>
                                        </FormControl>

                                        <TextField
                                            autoFocus margin='normal' variant='outlined' type='text' fullWidth
                                            placeholder="Résume" label="Resume" multiline rows={3} value={resume} onChange={event => setResume(event.target.value)}
                                        />
                                        <TextField
                                            margin='normal' variant='outlined' type='text' fullWidth
                                            placeholder="Texte initial" label="Text initial" multiline rows={3} value={init} onChange={event => setInit(event.target.value)}
                                        />
                                        <TextField
                                            margin='normal' variant='outlined' type='text' fullWidth
                                            placeholder="Lien vers le texte au format initial" label="Lien" multiline rows={3} value={link} onChange={event => setLink(event.target.value)}
                                        />
                                    </DialogContent>

                                    <DialogActions sx={{ px: "19px" }}>
                                        <Button onClick={updateDataText} variant="contained" sx={[{ bgcolor: '#157200' }, { "&:hover": { bgcolor: 'green' } }]} startIcon={<Save />}>
                                            {t("admin.btn_edit")}
                                        </Button>
                                    </DialogActions>
                                </form>
                            </Dialog>
                            <Dialog open={openValidate} onClose={() => setOpenValidate(false)}>
                                <DialogTitle>
                                    <Typography> {t("admin.validate_data_text")} </Typography>

                                    <IconButton
                                        sx={{
                                            position: 'absolute',
                                            top: '0.5rem',
                                            right: '0.5rem',
                                            color: (theme) => theme.palette.grey[500]
                                        }}
                                        onClick={() => setOpenValidate(false)}
                                    >
                                        <Close />
                                    </IconButton>
                                </DialogTitle>

                                <form>
                                    <DialogContent sx={{ py: '2rem' }} dividers>
                                        <Paper sx={{ position: 'relative', p: 1, my: 1 }} variant="outlined">
                                            <Typography sx={{ position: 'absolute', left: 2, top: -1, ml: 1, mt: -2, bgcolor: '#fff', px: 1 }} variant="h6">
                                                {t('admin.ttl_resume')}
                                            </Typography>
                                            <Typography sx={{ pt: 2 }} variant="body1">
                                                {selectedRow?.resume}
                                            </Typography>
                                        </Paper>
                                        <Paper sx={{ position: 'relative', p: 1, mt: 2 }} variant="outlined">
                                            <Typography sx={{ position: 'absolute', left: 2, top: -1, ml: 1, mt: -2, bgcolor: '#fff', px: 1 }} variant="h6">
                                                {t('admin.ttl_initial')}
                                            </Typography>
                                            <Typography sx={{ pt: 2 }} variant="body1">
                                                {selectedRow?.init_text}
                                            </Typography>
                                        </Paper>
                                    </DialogContent>

                                    <DialogActions sx={{ px: "19px" }}>
                                        <Button onClick={rejectText} color="error" startIcon={<Clear />} variant="outlined" sx={{ textTransform: 'none' }}>
                                            {t('admin.btn_reject')}
                                        </Button>
                                        <Button onClick={validateText} variant="contained" sx={[{ bgcolor: '#157200', textTransform: 'none' }, { "&:hover": { bgcolor: 'green' } }]} startIcon={<Done />}>
                                            {t("admin.btn_validate")}
                                        </Button>
                                    </DialogActions>
                                </form>
                            </Dialog>

                        </Grid>
                        <Grid item>
                            {currentUser.userRole === "Editor" && (
                            <Button onClick={() => handleOpenModal()} sx={{ textTransform: 'none', fontWeight: '600' }} variant="contained" startIcon={<Add />}>
                                {t("admin.add_data_text")}
                            </Button>
                            ) }
                            <Dialog open={open} onClose={() => setOpen(false)}>
                                <DialogTitle>
                                    <Typography> {t("admin.add_data_text")} </Typography>

                                    <IconButton
                                        sx={{
                                            position: 'absolute',
                                            top: '0.5rem',
                                            right: '0.5rem',
                                            color: (theme) => theme.palette.grey[500]
                                        }}
                                        onClick={() => setOpen(false)}
                                    >
                                        <Close />
                                    </IconButton>
                                </DialogTitle>

                                <form>
                                    <DialogContent sx={{ py: '2rem' }} dividers>
                                        <FormControl fullWidth>
                                            <InputLabel id="select-label">{t("admin.choose_language")}</InputLabel>
                                            <Select
                                                labelId="select-label"
                                                id="select"
                                                value={langAdd}
                                                label={t("admin.choose_language")}
                                                onChange={handleChangeAdd}
                                            >
                                                <MenuItem value="ar"> {t('lang.lang_ar')} </MenuItem>
                                                <MenuItem value="en"> {t('lang.lang_en')} </MenuItem>
                                                <MenuItem value="fr"> {t('lang.lang_fr')} </MenuItem>
                                                <MenuItem value="pt"> {t('lang.lang_pt')} </MenuItem>
                                            </Select>
                                        </FormControl>

                                        <TextField
                                            autoFocus margin='normal' variant='outlined' type='text' fullWidth
                                            placeholder="Résume" multiline rows={3} value={resume} onChange={event => setResume(event.target.value)}
                                        />
                                        <TextField
                                            margin='normal' variant='outlined' type='text' fullWidth
                                            placeholder="Texte initial" multiline rows={3} value={init} onChange={event => setInit(event.target.value)}
                                        />
                                        <TextField
                                            margin='normal' variant='outlined' type='text' fullWidth
                                            placeholder="Lien vers le texte au format initial" label="Lien" multiline rows={3} value={link} onChange={event => setLink(event.target.value)}
                                        />
                                    </DialogContent>

                                    <DialogActions sx={{ px: "19px" }}>
                                        <Button onClick={addDataText} variant="contained" sx={[{ bgcolor: '#157200' }, { "&:hover": { bgcolor: 'green' } }]} startIcon={<Add />}>
                                            {t("admin.btn_add")}
                                        </Button>
                                    </DialogActions>
                                </form>
                            </Dialog>

                        </Grid>
                    </Grid>

                    <Tabs sx={{ minWidth: '0', bgcolor: '#f5fdf6', my: 2 }} indicatorColor="#157200" allowScrollButtonsMobile value={activeActor} onChange={handlechange} variant="scrollable" scrollButtons='auto' aria-label="scrollable auto tabs">
                        {message.map((msg, i) => (
                            <Tab key={i} sx={[{
                                '&.Mui-selected': {
                                    color: '#157200',
                                    fontWeight: '600',
                                    borderBottom: "3px solid",
                                    borderBottomColor: "#157200"
                                }
                            }, { fontWeight: '500', fontSize: '1rem', textTransform: 'none', textAlign: 'left', mx: '0.2rem', color: i === activeActor ? '#157200' : '#000', py: '0.2rem' }]} label={msg.actor}
                                onClick={event => handlesetSubDomainId(event, msg)}
                            />
                        ))}
                    </Tabs>

                    {
                        data.length === 0 ? (<div style={{ padding: "2rem 10rem" }}> <CircularProgress /> </div>) :
                            <DataGrid
                                columns={columns}
                                rows={boites}
                                getRowId={(row) => row.uuid}
                                autoHeight
                                rowsPerPageOptions={[5, 10, 25]}
                                pageSize={pageSize}
                                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            />
                    }
                </Grid2>
            </Box>
        </AdminLayout>
    )
}
export default Commerce