import axiosv from "axios";

 const axios = axiosv.create({
    baseURL: "https://cilss.ubrainsoft.net/api/",
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    }
})
axios.interceptors.request.use(function(config){
    const token_bearer  = 'Bearer mHf1toDHlu0gcjHmCgAr2k6jp7sWUc0DzvB79gjX9aLLwMpqrX3YS3LQJUU3TQeWk9lTb8D003vE9T8f1TelRuggRUliKUXGDQLiWAZMFMZYVAEdMI4tEwGp';
    config.headers.Authorization = token_bearer
    return config;
})

export default axios