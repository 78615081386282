import { useEffect } from "react";
import Baner from "../components/ui/baner";
import { Accordion, AccordionDetails, AccordionSummary, Box, Container, Typography } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import Layout from "../components/ui/layout";
import Bg from "../images/form_bg.png"
import { useTranslation } from "react-i18next";

export default function Procedures() {
    const {t} = useTranslation('global')
    const procedures = [
        {
            title: t('proccess.item_pesticide_title'), content: <Box>
                <Typography sx={{ textAlign: 'justify' }}>
                    { t('proccess.item_pesticide_describe') }
                </Typography>

                <Typography sx={{ textAlign: 'justify' }}>
                    { t('proccess.item_pesticide_describe_suite') }
                </Typography>
                <ul>
                    <li>
                        { t('proccess.item_pesticide_text_1') }
                    </li>
                    <li>
                        { t('proccess.item_pesticide_text_2') }
                    </li>
                    <li>
                        { t('proccess.item_pesticide_text_3') }
                    </li>
                    <li>
                        { t('proccess.item_pesticide_text_4') }
                    </li>
                    <li>
                        { t('proccess.item_pesticide_text_5') }
                    </li>
                    <li>
                        { t('proccess.item_pesticide_text_6') }
                    </li>
                    <li>
                        { t('proccess.item_pesticide_text_7') }
                    </li>
                </ul>
            </Box>
        },
        {
            title: t('proccess.item_semences_title'), content: <Box>
                <Typography sx={{ textAlign: 'justify' }}>
                    { t('proccess.item_semences_describe') }
                </Typography>

                <Typography sx={{ textAlign: 'justify' }}>
                    { t('proccess.item_semences_describe_suite_1') }
                </Typography>

                <Typography sx={{ textAlign: 'justify' }}>
                    { t('proccess.item_semences_describe_suite_2') }
                </Typography>

                <Typography sx={{ textAlign: 'justify' }}>
                    { t('proccess.item_semences_describe_suite_3') }
                </Typography>

                <Typography sx={{ textAlign: 'justify' }}>
                    { t('proccess.item_semences_describe_suite_4') }
                </Typography>
                <ul>
                    <li>
                        { t('proccess.item_semences_text_1') }
                    </li>
                    <li>
                        { t('proccess.item_semences_text_2') }
                    </li>
                    <li>
                        { t('proccess.item_semences_text_3') }
                    </li>
                    <li>
                        { t('proccess.item_semences_text_4') }
                    </li>
                    <li>
                        { t('proccess.item_semences_text_5') }
                    </li>
                    <li>
                        { t('proccess.item_semences_text_6') }
                    </li>
                    <li>
                        { t('proccess.item_semences_text_7') }
                    </li>
                    <li>
                        { t('proccess.item_semences_text_8') }
                    </li>
                    <li>
                        { t('proccess.item_semences_text_9') }
                    </li>
                </ul>
            </Box>
        },
        {
            title: t('proccess.item_engrais_title'), content: <Box>
                <Typography sx={{ textAlign: 'justify' }}>
                    { t('proccess.item_engrais_describe') }
                </Typography>

                <Typography sx={{ textAlign: 'justify' }}>
                    { t('proccess.item_engrais_describe_suite_1') }
                </Typography>

                <Typography sx={{ textAlign: 'justify' }}>
                    { t('proccess.item_engrais_describe_suite_2') }
                </Typography>

                <Typography sx={{ textAlign: 'justify' }}>
                    { t('proccess.item_engrais_describe_suite_3') }
                </Typography>
                <ul>
                    <li>
                        { t('proccess.item_engrais_text_1') }
                    </li>
                    <li>
                        { t('proccess.item_engrais_text_2') }
                    </li>
                    <li>
                        { t('proccess.item_engrais_text_3') }
                    </li>
                    <li>
                        { t('proccess.item_engrais_text_4') }
                    </li>
                    <li>
                        { t('proccess.item_engrais_text_5') }
                    </li>
                    <li>
                        { t('proccess.item_engrais_text_6') }
                    </li>
                    <li>
                        { t('proccess.item_engrais_text_7') }
                    </li>
                    <li>
                        { t('proccess.item_engrais_text_8') }
                    </li>
                    <li>
                        { t('proccess.item_engrais_text_9') }
                    </li>
                    <li>
                        { t('proccess.item_engrais_text_10') }
                    </li>
                </ul>
            </Box>
        },
        {
            title: t('proccess.item_betail_title'), content: <Box>
                <Typography sx={{ textAlign: 'justify' }}>
                    { t('proccess.item_betail_describe') }
                </Typography>
                <Typography sx={{ textAlign: 'justify' }}>
                    { t('proccess.item_betail_describe_suite_1') }
                </Typography>
                <Typography sx={{ textAlign: 'justify' }}>
                    { t('proccess.item_betail_describe_suite_2') }
                </Typography>
                <Typography sx={{ textAlign: 'justify' }}>
                    { t('proccess.item_betail_describe_suite_3') }
                </Typography>
                <Typography sx={{ textAlign: 'justify' }}>
                    { t('proccess.item_betail_describe_suite_4') }
                </Typography>
                <Typography sx={{ textAlign: 'justify' }}>
                    { t('proccess.item_betail_describe_suite_5') }
                </Typography>
                <Typography sx={{ textAlign: 'justify' }}>
                    { t('proccess.item_betail_describe_suite_6') }
                </Typography>
                <Typography sx={{ textAlign: 'justify' }}>
                    { t('proccess.item_betail_describe_suite_7') }
                </Typography>
                <Typography sx={{ textAlign: 'justify' }}>
                    { t('proccess.item_betail_describe_suite_8') }
                </Typography>
                <Typography sx={{ textAlign: 'justify' }}>
                    { t('proccess.item_betail_describe_suite_9') }
                </Typography>
                <Typography sx={{ textAlign: 'justify' }}>
                    { t('proccess.item_betail_describe_suite_10') }
                </Typography>
                <ul>
                    <li>
                        { t('proccess.item_betail_text_1') }
                    </li>
                    <li>
                        { t('proccess.item_betail_text_2') }
                    </li>
                </ul>
            </Box>
        },
        {
            title: t('proccess.item_vegetal_product_title'), content: <Box>
                <Typography sx={{ textAlign: 'justify' }}>
                    { t('proccess.item_vegetal_describe') }
                </Typography>
                <Typography sx={{ textAlign: 'justify' }}>
                    { t('proccess.item_vegetal_describe_suite_1') }
                </Typography>
                <Typography sx={{ textAlign: 'justify' }}>
                    { t('proccess.item_vegetal_describe_suite_2') }
                </Typography>
                <Typography sx={{ textAlign: 'justify' }}>
                    { t('proccess.item_vegetal_describe_suite_3') }
                </Typography>
                <Typography sx={{ textAlign: 'justify' }}>
                    { t('proccess.item_vegetal_describe_suite_4') }
                </Typography>
                <Typography sx={{ textAlign: 'justify' }}>
                    { t('proccess.item_vegetal_describe_suite_5') }
                </Typography>
                <Typography sx={{ textAlign: 'justify' }}>
                    { t('proccess.item_vegetal_describe_suite_6') }
                </Typography>
                <Typography sx={{ textAlign: 'justify' }}>
                    { t('proccess.item_vegetal_describe_suite_7') }
                </Typography>
                <Typography sx={{ textAlign: 'justify' }}>
                    { t('proccess.item_vegetal_describe_suite_8') }
                </Typography>
            </Box>
        },
        {
            title: t('proccess.item_transhumance_title'), content: <Box>
                <Typography sx={{ textAlign: 'justify' }}>
                    { t('proccess.item_transhumance_describe') }
                </Typography>
                <Typography sx={{ textAlign: 'justify' }}>
                    { t('proccess.item_transhumance_describe_suite_1') }
                </Typography>
                <Typography sx={{ textAlign: 'justify' }}>
                    { t('proccess.item_transhumance_describe_suite_2') }
                </Typography>
                <Typography sx={{ textAlign: 'justify' }}>
                    { t('proccess.item_transhumance_describe_suite_3') }
                </Typography>
                <Typography sx={{ textAlign: 'justify' }}>
                    { t('proccess.item_transhumance_describe_suite_4') }
                </Typography>
                <Typography sx={{ textAlign: 'justify' }}>
                    { t('proccess.item_transhumance_describe_suite_5') }
                </Typography>
                <Typography sx={{ textAlign: 'justify' }}>
                    { t('proccess.item_transhumance_describe_suite_6') }
                </Typography>
                <ul>
                    <li>
                        { t('proccess.item_transhumance_text_1') }
                    </li>
                    <li>
                        { t('proccess.item_transhumance_text_2') }
                    </li>
                    <li>
                        { t('proccess.item_transhumance_text_3') }
                    </li>
                </ul>
            </Box>
        }
    ]

    useEffect(() => {
        document.title = t('proccess.title');
    }, []);

    return (
        <Layout>
            <Baner title={t('proccess.about')} subTitle={t('proccess.sub_title')} />
            <Box sx={{width: '100%', backgroundImage: `url(${Bg})`, backgroundSize: 'cover', backgroundPosition: 'top', backgroundRepeat: 'no-repeat'}}>
            <Container sx={{ py: 2 }}>
                <Typography sx={{textAlign: 'justify', my: 2, borderLeft: '5px solid', borderColor: "#157200", pl: 2, bgcolor: '#fff'}}>
                    { t('proccess.describe') }
                </Typography>
                
                {procedures.map((form, i) => (
                    <Accordion key={form.title} defaultExpanded={i === 0}>
                        <AccordionSummary
                            expandIcon={<ExpandMore color="secondary" />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <Typography sx={{ fontWeight: '600', color: '#157200' }} variant="h6">{form.title}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {form.content}
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Container>
            </Box>
        </Layout>
    )
}