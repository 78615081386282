import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material"
import { useValue } from "../../../context/ContextProvider"
import PasswordField from "./password_field"
import { useRef } from "react"
import { Close } from "@mui/icons-material"
import axios from "axios"
import { useTranslation } from "react-i18next"

const EditPassword = () => {
    const { state: { pwd, currentUser }, dispatch } = useValue()
    const {t} = useTranslation('global')

    const currentPwdRef = useRef()
    const newPwdRef = useRef()
    const confirmPwdRef = useRef()

    const handleClose = () => {
        dispatch({ type: "CLOSE_PWD" })
    }


    const handleSubmit = async(e) =>{
        e.preventDefault()
        dispatch({ type: "START_LOADING" })

        const currentPwd = currentPwdRef.current.value
        const newPwd = newPwdRef.current.value
        const confirmPwd = confirmPwdRef.current.value

        const data = {
            user_id: currentUser.userId,
            current_password: currentPwd,
            new_password: newPwd,
            confirm_password: confirmPwd
        }

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${currentUser.token}`
            }
        }
        if(newPwd === confirmPwd){
            axios.post('update_password', data, config).then(res=>{
                if(res.status === 200){
                    dispatch({
                        type: 'UPDATE_ALERT',
                        payload: {
                            open: true,
                            severity: 'success',
                            message: "Mot de passse mis à jour"
                        }
                    })
                    handleClose()
                }
            }).catch(err =>{
                console.log(err)
                dispatch({
                    type: 'UPDATE_ALERT',
                    payload: {
                        open: true,
                        severity: 'error',
                        message: err.message
                    }
                })
            })
        } else{
            dispatch({
                type: 'UPDATE_ALERT',
                payload: {
                    open: true,
                    severity: 'error',
                    message: "Le mot de passse  et la confirmation sont différents"
                }
            })
        }
        dispatch({ type: "END_LOADING" })
    }

    return (
        <Dialog open={pwd} onClose={handleClose}>
            <DialogTitle>
                <Typography>{t('admin.pwd_edtg_ttl')}</Typography>
                <IconButton
                    sx={{
                        position: 'absolute',
                        top: '0.5rem',
                        right: '0.5rem',
                        color: (theme) => theme.palette.grey[500]
                    }}
                    onClick={handleClose}>
                    <Close />
                    </IconButton>
            </DialogTitle>
            <form onSubmit={handleSubmit}>
            <DialogContent>
                <PasswordField id="1" passwordRef={currentPwdRef} label={t('admin.crrt_pwd')} />
                <PasswordField id="2" passwordRef={newPwdRef} label={t('admin.new_pwd')} />
                <PasswordField id="3" passwordRef={confirmPwdRef} label={t("admin.confirm_new_pwd")} />
            </DialogContent>
            <DialogActions>
                <Button type="submit" variant="contained" sx={{ textTransform: 'none', fontWeight: '600' }}>
                    {t("admin.btn_confirm")}
                </Button>
            </DialogActions>
            </form>
        </Dialog>
    )
}
export default EditPassword