import { createContext, useContext, useEffect, useReducer } from "react"
import reducer from "./reducer"


const initialState = {
  currentUser: null,
  openLogin: false,
  loading: false,
  alert: { open: false, severity: 'info', message: '' },
  commerce_text: null,
  actors: [],
  users: [],
  domains: [],
  notif_count: null,
  profile: { open: false, file: null, photoURL: '' },
  pwd: false

}

const Context = createContext(initialState)

export const useValue = () => {
  return useContext(Context)
}

const ContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  useEffect(() => {
    const fetchUserFromLocalStorage = () => {
      dispatch({ type: 'START_LOADING' })
      const currentUser = JSON.parse(localStorage.getItem('currentUser'))
      if (currentUser) {
        dispatch({ type: 'UPDATE_USER', payload: currentUser })
      }
      dispatch({ type: 'END_LOADING' })
    }
    fetchUserFromLocalStorage()
  }, [])
  return (
    <Context.Provider value={{ state, dispatch }}>{state?.loading ? null : children}</Context.Provider>
  )
}

export default ContextProvider