import { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import Iconify from '../../../../components/iconify';
import { useValue } from '../../../../context/ContextProvider';
import { getNotifs } from '../../../../actions/utils/getNotifs';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// ----------------------------------------------------------------------


export default function NotificationsPopover() {
  const {state:{currentUser, notif_count}, dispatch} = useValue()
  const {t} = useTranslation('global')
  const navigate = useNavigate()

  useEffect(()=>{
		if(notif_count === null){
			getNotifs(currentUser, dispatch)
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[])


  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <IconButton color={open ? 'primary' : 'default'} onClick={handleOpen}>
        <Badge badgeContent={notif_count} color="error">
          <Iconify width={24} icon="solar:bell-bing-bold-duotone" />

        </Badge>
      </IconButton>

      <Popover
        open={!!open}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            mt: 1.5,
            ml: 0.75,
            width: 360,
          },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {t('admin.notif_tr_r')} {notif_count} {t('admin.notif_nkmtv')}
            </Typography>
          </Box>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Typography sx={{p: 2}} variant="body1"> {t('admin.notif_advc')} </Typography>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Box display='flex'  justifyContent='space-between' sx={{ p: 1 }}>
          <Button onClick={()=>navigate('/admin/commerce')} sx={{textTransform: 'none', fontWeight: '600'}}  disableRipple>
            {t('menu.Trade')}
          </Button>
          <Button onClick={()=>navigate('/admin/transport')} sx={{textTransform: 'none', fontWeight: '600'}} disableRipple>
            {t("menu.Transport")}
          </Button>
        </Box>
      </Popover>
    </>
  );
}
