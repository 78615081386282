import fetchData from "./utils/fetchData"

export const getActors = async (dispatch) => {
    dispatch({ type: 'START_LOADING' });

    try {
        const actors = await fetchData({url: "get_sub_domains"}, dispatch);
        const actorArray = Object.keys(actors).map(lang => ({lang, items: actors[lang]}))
        if (actors) {
            dispatch({type: 'UPDATE_ACTORS', payload: actorArray});
        }
    } finally {
    }
    dispatch({ type: 'END_LOADING' });
}

export const getDomains = async (dispatch) => {
    dispatch({ type: 'START_LOADING' });

    try {
        const domains = await fetchData({url: "get_domains"}, dispatch);
        if (domains) {
            dispatch({type: 'UPDATE_DOMAINS', payload: domains});
        }
    } catch(err) {
        dispatch({
            type: 'UPDATE_ALERT',
            payload: {
                open: true,
                severity: 'error',
                message: err.message
            }
        });
        console.log(err.message)
    }
    dispatch({ type: 'END_LOADING' });

}