import { Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { lazy } from 'react';
import Home from './pages/home.js';
import Contact from './pages/contact.js';
import Help from './pages/help.js';
import Archive from './pages/archive.js';
import Media from './pages/media.js';
import Commerce from './pages/commerce.js';
import Transport from './pages/transport.js';
import Dashboard from './pages/admin/dashboard.js'
import Categories from './pages/admin/categories.js';

import Notification from './components/ui/notification.js';
import Loading from './components/ui/loading.js';
import Protected from './components/protected/protected.js';
import EditPassword from './components/ui/user/edit_password.js';
import Market from './pages/market.js';
import Formalities from './pages/formalities.js';
import Procedures from './pages/procedures.js';
import Report from './pages/report.js';
import Doccommerce from  './pages/doccommerce.js';
import Doctransport from  './pages/doctransport.js';
import Bulletin from './pages/bulletin.js';
import AdminCommerce from "./pages/admin/commerce.js"
// export const AdminCommerce = lazy(() => import("./pages/admin/commerce.js"));
import AdminTransport from "./pages/admin/transport.js"
// export const AdminTransport = lazy(() => import("./pages/admin/transport.js"));
// import Videos from './pages/admin/videos.js';
export const Videos = lazy(() => import('./pages/admin/videos.js'));
// import Emissions from './pages/admin/emissions.js';
export const Emissions = lazy(() => import('./pages/admin/emissions.js'));
// import Magazines from './pages/admin/magazines.js';
export const Magazines = lazy(() => import('./pages/admin/magazines.js'));
// import Images from './pages/admin/images.js';
export const Images = lazy(() => import('./pages/admin/images.js'));
// import Login from './components/ui/user/login.js';
export const Login = lazy(() => import('./components/ui/user/login.js'));

// import Users from './pages/admin/users.js';
export const Users = lazy(() => import('./pages/admin/users.js'));
// import Opportunities from './pages/opportunities.js';
export const Opportunities = lazy(() => import('./pages/opportunities.js'));
// import OpportunitiesAdmin from './pages/admin/opportunities.js'
export const OpportunitiesAdmin = lazy(() => import('./pages/admin/opportunities.js'));
// export const Market = lazy(() => import('./pages/market.js'));
// import Sliders from './pages/admin/sliders.js';
export const Sliders = lazy(() => import('./pages/admin/sliders.js'));
// import LoginPage from './pages/admin/login.js';
export const LoginPage = lazy(() => import('./pages/admin/login.js'));
// import Documents from './pages/admin/documents.js';
export const Documents = lazy(() => import('./pages/admin/documents.js'));
// import QRCodePage from './pages/qrcodepage.js';
export const QRCodePage = lazy(() => import('./pages/qrcodepage.js'));
// export const Formalities = lazy(() => import('./pages/formalities.js'));
// export const Procedures = lazy(() => import('./pages/procedures.js'));
// import Policy from './pages/policy.js';
export const Policy = lazy(() => import('./pages/policy.js'));

function App() {
  return (
    <>
      <BrowserRouter>
        <Loading />
        <Notification />
        <Login />
        <EditPassword />
        <Suspense>
        <Routes>
          <Route index element={<Home />} />
          <Route path='/' element={<Home />} />
          <Route path='/resources/procedures-commerciales' element={<Archive />} />
          <Route path='/resources/bulletin-information' element={<Bulletin />} />
          <Route path='/resources/report' element={<Report />} />
          <Route path='/resources/commerce' element={<Doccommerce />} />
          <Route path='/resources/transport' element={<Doctransport />} />
          {/* <Route path='/resources' element={<Archive />} /> */}
          <Route path='/messages/commerce' element={<Commerce />} />
          <Route path='/messages/transport' element={<Transport />} />
          <Route path='/formproc/formalities' element={<Formalities />} />
          <Route path='/formproc/procedures' element={<Procedures />} />
          <Route path='/help' element={<Help />} />
          <Route path='/media' element={<Media />} />
          <Route path='/contacts' element={<Contact />} />
          <Route path='/opportunities' element={<Opportunities />} />
          <Route path='/market' element={<Market />} />
          <Route path='/commerce' element={<Commerce />} />
          <Route path='/users' element={<Users />} />
          <Route path="/qrcode" element={<QRCodePage />} />
          <Route path='/policy' element={<Policy />} />

          <Route>
            <Route path="/admin/*" element={ <Protected> <Dashboard /> </Protected> } />
            <Route path='/admin/dashboard' element={<Protected> <Dashboard /> </Protected>} />
            <Route path='/admin/opportunities' element={<Protected> <OpportunitiesAdmin /> </Protected>} />
            <Route path='/admin/categories' element={<Protected><Categories /></Protected>} />
            <Route path='/admin/commerce' element={<Protected><AdminCommerce /></Protected>} />
            <Route path='/admin/transport' element={<Protected><AdminTransport /></Protected>} />
            <Route path='/admin/videos' element={<Protected><Videos /></Protected>} />
            <Route path='/admin/emissions' element={<Protected><Emissions /></Protected>} />
            <Route path='/admin/magazines' element={<Protected><Magazines /></Protected>} />
            <Route path="/admin/images" element={<Protected><Images /></Protected>} />
            <Route path="/admin/users" element={<Protected><Users /></Protected>} />
            <Route path="/admin/sliders" element={<Protected><Sliders /></Protected>} />
            <Route path="/admin/docs" element={<Protected><Documents /></Protected>} />
            <Route path='/admin/login' element={<LoginPage />} />
          </Route>
        </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  );
}

export default App;
