import React, { useEffect, useState } from "react";
import Layout from "../components/ui/layout";

import Baner from "../components/ui/baner";
import { Box, Card, CircularProgress, Container, Divider, Grid, Tab, Tabs, alpha } from "@mui/material";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import axios from "../api/without_user_token";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import Podcast from '../images/podcast.jpg'
import { Radio, VideoLibrary, VolumeUp } from "@mui/icons-material";
import { grey } from "@mui/material/colors";

export default function Media() {
  const { t, i18n } = useTranslation("global");

  useEffect(() => {
    document.title = t('media.page_ttl');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [mediaIndex, setMediaIndex] = useState(0);
  const [videos, setVideos] = useState([]);
  const [emissions, setEmission] = useState([]);
  const [selectedFile, setSelectedFile] = useState(0)
  const [medias, setMedias] = useState(null);

  useEffect(() => {

    const fetchData = async () => {
      try {
        const videosResponse = await axios.get("get_all_media_by_type", {
          params: { type: "VIDEO", lang: i18n.language },
          headers: { "Content-Type": "application/json" },
        });
        const emissionsResponse = await axios.get("get_all_media_by_type", {
          params: { type: "AUDIO", lang: i18n.language },
          headers: { "Content-Type": "application/json" },
        });

        if (videosResponse.status === 200 && emissionsResponse.status === 200) {
          setVideos(videosResponse.data[i18n.language]);
          setEmission(emissionsResponse.data[i18n.language]);
          setMedias([
            { title: t("theques.Videos"), files: videosResponse.data[i18n.language] },
            { title: t("theques.radio_broadcast"), files: emissionsResponse.data[i18n.language] },
          ]);
        }
      } catch (error) {
        console.log("Error occur in fetchData: " + error);
      }
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);


  const handleChange = (event, newValue) => {
    setMediaIndex(newValue);
  };
  const handleFileChange = (event, newValue) => {
    setSelectedFile(newValue)
  }
  return (
    <Layout>
      <Baner title={t('media.ttl')} subTitle={t('media.sub_ttl')} />
      <Container sx={{ py: '1rem' }}>
        <Grid container>
          <Grid item xs={12} sm={12} sx={{ pt: 1 }} container >
            <Grid
              item
              container
              alignItems="flex-start"
              alignContent="flex-start"
            >
              {medias !== null ? (
                <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%', mb: 1 }}>
                  <Card elevation={3}>
                    <Tabs value={mediaIndex} onChange={handleChange} aria-label="basic tabs example">
                      {medias?.map((media, i) => (
                        <Tab icon={i === 0 ? <VideoLibrary /> : <Radio />} iconPosition="start" key={i} sx={{ textTransform: 'none', fontWeight: '600' }} label={media?.title} />
                      ))}
                    </Tabs>
                  </Card>
                </Box>
              ) : (<Box variant="div" display="flex" sx={{ width: '100%', height: '100%' }} alignContent="center"> <CircularProgress sx={{ margin: "auto auto" }} /> </Box>)}
              {medias !== null && <Divider />}
            </Grid>
            {medias !== null ? (
              <Grid
                item
                container
                sx={{
                  mt: 1,
                  borderRadius: "0",
                }}
              >

                <Grid item sx={{ height: { xs: 300, sm: 400, md: 500, lg: 550 }, pr: 0.5 }} xs={12} sm={9} md={9}>
                  {mediaIndex === 0 ? (
                    <ReactPlayer style={{ minWidth: 1 }} width="100%" height="100%" url={medias[mediaIndex]?.files[selectedFile]?.link} />
                  ) : (
                    <Box display="flex" flexDirection="column" variant="div">
                      <Box variant="div" sx={{height: { xs: 150, sm: 200, md: 250, lg: 275 }, width: '100%', py: 10, backgroundImage: `url(${Podcast})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center'}} >
                        <div />
                      </Box>
                      <AudioPlayer preload={true} autoPlay={false} src={medias[mediaIndex]?.files[selectedFile]?.file_path} />
                    </Box>
                  )}
                </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <Box
                      sx={{ bgcolor: '#157200', display: 'flex', py: 1, height: '92%' }}
                    >
                      <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        textColor="#000"
                        indicatorColor="#FDF50D"
                        value={selectedFile}
                        onChange={handleFileChange}
                        aria-label="Vertical tabs example"
                        sx={{ flexGrow: 1, borderRight: 1, borderColor: '#FDF50D' }}
                      >
                        {medias[mediaIndex]?.files?.map((file, i) => (
                          <Tab key={i} icon={selectedFile === i ? <VolumeUp fontSize="small" /> : null} iconPosition={selectedFile === i ? 'end' : undefined} sx={{ textTransform: 'none', width: '100%', fontWeight: '600', borderRightColor: '#FDF50D', color: selectedFile === i ? "#000" : grey[200], textAlign: 'left', bgcolor: selectedFile === i && alpha('#FDF50D', 1) }} label={file.name} />
                        ))}
                      </Tabs>
                    </Box>
                  </Grid>


              </Grid>

            ) : (<Box variant="div" display="flex" sx={{ width: '100%', height: '100%' }} alignContent="center"> <CircularProgress sx={{ margin: "auto auto" }} /> </Box>)}
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
}
