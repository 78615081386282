import React, { useState, useEffect } from "react";
import {
  CircularProgress,
  Grid,
  Link,
  Typography,
  Card,
  Tabs,
  Tab,
  Box,
  Container,
  Button,
  Paper,
  Tooltip,
  Divider,
  alpha,
  CardContent,
  CardActions,
} from "@mui/material";
import ReactPlayer from 'react-player';
import { useTranslation } from "react-i18next";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import RadioIcon from "@mui/icons-material/Radio";
import CategoryIcon from "@mui/icons-material/Category";
import { useNavigate } from "react-router-dom";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import axios from "../../../api/without_user_token";
import { grey } from "@mui/material/colors";
import { ArrowRightAlt, Download, VolumeUp } from "@mui/icons-material";
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

import Podcast from '../../../images/podcast.jpg'


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();


const Theques = () => {
  const { t, i18n } = useTranslation("global");
  const [videos, setVideos] = useState([]);
  const [emissions, setEmissions] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [categories, setCategories] = useState([]);
  const [activeMediaTab, setActiveMediaTab] = useState(0);
  const [selectedFile, setSelectedFile] = useState(0)
  const [activeTab, setActiveTab] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const navigate = useNavigate()


  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  }




  const categoryIcons = {
    Médias: <VideoLibraryIcon />,
    Emission: <RadioIcon />,
  };

  useEffect(() => {

    const getVideos = async () => {
      try {
        const response = await axios.get("get_all_media_by_type", {
          params: { type: "VIDEO", lang: i18n.language },
          headers: { "Content-Type": "application/json" },
        });
        if (response.status === 200) {
          setVideos(response.data[i18n.language]);
        }
      } catch (error) {
        console.log("Error occur in getVideos: " + error);
      }
    };

    const getEmissions = async () => {
      try {
        const response = await axios.get("get_all_media_by_type", {
          params: { type: "AUDIO", lang: i18n.language },
          headers: { "Content-Type": "application/json" },
        });
        if (response.status === 200) {
          setEmissions(response.data[i18n.language]);
        }
      } catch (error) {
        console.log("Error occur in getEmissions: " + error);
      }
    };


    const fetchDocuments = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get("get_document", {
          headers: { Authorization: `Bearer` },
        });
        const allDocuments = Object.values(response.data).flat();
        const byLang = allDocuments.filter(doc => doc.lang === i18n.language)
        setDocuments(byLang.length > 5 ? byLang.slice(-6) : byLang);
        const extractedCategories = [t('all_m'), ...new Set(byLang.map((doc) => doc.type_file))];
        setCategories(extractedCategories);
      } catch (error) {
        console.error("Erreur lors de la récupération des documents:", error);
      } finally {
        setIsLoading(false);
      }
    };

    setVideos(null);
    setEmissions(null);
    setDocuments([]);
    setCategories([]);

    getVideos();
    getEmissions();
    fetchDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);



  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleFileChange = (event, newValue) => {
    setSelectedFile(newValue)
  }


  return (

    <Box sx={{ width: '100%' }}>
      <Grid container direction='column' spacing={1}>

        <Grid item xs={12} sx={{ bgcolor: "#157200", py: 4 }}>
          <Container>
            <Typography sx={{ fontWeight: '700', fontSize: '1.875rem', color: "#fff", textAlign: 'center', py: 2 }} variant="h5">{t('theques.some_docs')}</Typography>
            <Paper sx={{ mb: 2 }}>
              <Tabs
                value={activeTab}
                onChange={handleTabChange}
                orientation="horizontal"
                indicatorColor="secondary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
              >
                {categories.map((category, index) => (
                  <Tab key={index} sx={{ textTransform: 'none' }} label={index !== 0 ? t(`admin.type_${category.toLowerCase()}`) : category} icon={categoryIcons[category] || <CategoryIcon />} />
                ))}
              </Tabs>
            </Paper>
            <Grid item xs={12} spacing={1} container sx={{overflow: 'clip'}}>
              {isLoading ? <CircularProgress /> : (activeTab === 0 ? documents : documents.filter(doc => doc.type_file === categories[activeTab])).map((document, index) => (

                <Grid item xs={12} sm={6} md={4} key={document.uuid}>
                  <Card sx={{ borderBottom: '6px solid yellow', py: 0, px: 0 }}>
                    <CardContent sx={{ py: 0, px: 0 }}>
                      <Card variant="outlined" sx={{ height: 100, overflow: 'hidden', maxWidth: {sm: '100%', xs: 300, md: '100%', lg: '100%'}}}>
                        <Document file={document.path} onLoadSuccess={onDocumentLoadSuccess}>
                          <Page pageNumber={pageNumber} />
                        </Document>
                      </Card>
                      <Box sx={{ bgcolor: alpha('#fff', 0.9), zIndex: 2, px: 2 }}>
                        <Typography gutterBottom sx={{ height: 40 }} variant="h6">
                          {document.title.length > 32 ? (<Tooltip title={document.title.charAt(0).toUpperCase()
                            + document.title.slice(1).toLowerCase()}>
                            {`${document.title.charAt(0).toUpperCase()
                              + document.title.slice(1).toLowerCase().substring(0, 32)}...`}
                          </Tooltip>) : (document.title.charAt(0).toUpperCase()
                            + document.title.slice(1).toLowerCase())}
                        </Typography>
                        <Typography sx={{pt: 2}} variant="body2" color="textSecondary">
                          {`${t('docs.docs_cat')}: `} {t(`admin.type_${document.type_file.toLowerCase()}`)}
                        </Typography>
                      </Box>
                    </CardContent>
                    <CardActions sx={{ justifyContent: 'flex-start' }}>
                      <Button sx={{ py: 0, textTransform: 'none' }} startIcon={<Download />} size="small" color="primary" component={Link} href={document.path} target="_blank">
                        {t('docs.docs_dwnld')}
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
            <Box display='flex' justifyContent='flex-end' variant='div' >
              <Button endIcon={<ArrowRightAlt />} sx={{ textTransform: 'none', fontWeight: '600', color: "#fff" }} variant="text" color="primary" onClick={() => navigate("/documenthec")}>
                {t("See more")}
              </Button>
            </Box>
          </Container>
        </Grid>


        <Container sx={{ py: 2 }}>
          <Grid item conatiner xs={12} >
            <Typography sx={{ fontWeight: '700', fontSize: '1.875rem', textAlign: 'center' }} variant="h5" >{t('theques.some_medias')}</Typography>
            <Grid item xs={12} container>
              <Grid item xs={12} sm={12} sx={{ pt: 1 }} container >
                <Grid
                  item
                  container
                  alignItems="flex-start"
                  alignContent="flex-start"
                >
                  {videos !== null ? (
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%', mb: 1 }}>
                      <Card elevation={3}>
                        <Tabs value={activeMediaTab} onChange={(event, newValue) => setActiveMediaTab(newValue)} aria-label="basic tabs example">
                          <Tab sx={{ textTransform: 'none', color: '#ooo' }} icon={<VideoLibraryIcon />} label={t("Médias")} />
                          <Tab sx={{ textTransform: 'none', color: "#000" }} icon={<RadioIcon />} label={t("Emission")} />
                        </Tabs>
                      </Card>
                    </Box>
                  ) : (<Box variant="div" display="flex" sx={{ width: '100%', height: '100%' }} alignContent="center"> <CircularProgress sx={{ margin: "auto auto" }} /> </Box>)}
                  {videos !== null && <Divider />}
                </Grid>
                {(videos !== null && emissions !== null) ? (
                  <Grid
                    item
                    container
                    sx={{
                      mt: 1,
                      borderRadius: "0",
                    }}
                  >
                    <Grid item sx={{ height: { xs: 300, sm: 400, md: 500, lg: 550 }, pr: 0.5 }} xs={12} sm={9} md={9}>
                      {activeMediaTab === 0 ? (
                        <ReactPlayer style={{ minWidth: 1 }} width="100%" height="100%" url={(activeMediaTab === 0 ? videos : emissions)[selectedFile]?.link} />
                      ) : (
                        <Box display="flex" flexDirection="column" variant="div">
                          <Box variant="div" sx={{height: { xs: 150, sm: 200, md: 250, lg: 275 }, width: '100%', py: 10, backgroundImage: `url(${Podcast})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center'}} >
                        <div />
                      </Box>
                          <AudioPlayer preload={true} autoPlay={false} src={emissions[selectedFile]?.file_path} />
                        </Box>
                      )}
                    </Grid>
                      <Grid item xs={12} sm={3} md={3}>
                        <Box
                          sx={{ bgcolor: '#157200', display: 'flex', py: 1, height: '92%' }}
                        >
                          <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            textColor="#000"
                            indicatorColor="#FDF50D"
                            value={selectedFile}
                            onChange={handleFileChange}
                            aria-label="Vertical tabs example"
                            sx={{ flexGrow: 1, borderRight: 1, borderColor: '#FDF50D' }}
                          >
                            {(activeMediaTab === 0 ? videos : emissions).map((file, i) => (
                              <Tab key={i} icon={selectedFile === i ? <VolumeUp fontSize="small" /> : null} iconPosition={selectedFile === i ? 'end' : undefined} sx={{ textTransform: 'none', width: '100%', fontWeight: '600', borderRightColor: '#FDF50D', width: '100%', color: selectedFile === i ? "#000" : grey[200], textAlign: 'left', bgcolor: selectedFile === i && alpha('#FDF50D', 1) }} label={file.name} />
                            ))}
                          </Tabs>
                        </Box>
                      </Grid>
                  </Grid>
                ) : (<Box variant="div" display="flex" sx={{ width: '100%', height: '100%' }} alignContent="center"> <CircularProgress sx={{ margin: "auto auto" }} /> </Box>)}
              </Grid>
            </Grid>
            <Box display='flex' variant='div' justifyContent='flex-end'>
              <Button endIcon={<ArrowRightAlt />} variant="text" color="primary" sx={{ textTransform: 'none', fontWeight: '600', mt: 2 }} href="/media">
                {t("See more")}
              </Button>
            </Box>
          </Grid>
        </Container>


      </Grid>
    </Box >

  );
};

export default Theques;
