import { useEffect } from "react";
import { useValue } from "../context/ContextProvider";
import moment from "moment";
import { useNavigate } from "react-router-dom";


const useCheckConnexionDuration = () => {
    const navigate = useNavigate()
    const {
      dispatch,
    } = useValue();
    useEffect(() => {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'))
      if (currentUser !== null) {
        if(moment(currentUser.connected_at).add(15, 'minutes') < moment()){
            dispatch({ type: 'UPDATE_USER', payload: null });
            navigate('/admin/login', {replace: true})
        }
      }
    }, [dispatch, navigate]);
  };
  export default useCheckConnexionDuration