import React, { useState, useEffect } from "react";
import { useValue } from "../../../context/ContextProvider";
import { Avatar, Box, IconButton } from "@mui/material";
import UserMenu from "./user_menu";
import { useTranslation } from "react-i18next";
import LanguagePopover from "../admin/common/language-popover";
import NotificationsPopover from "../admin/common/notifications-popover";
import { getNotifs } from "../../../actions/utils/getNotifs";

const UserIcons = () => {
	const [anchorUserMenu, setAnchorUserMenu] = useState(null);
	const { state: { currentUser, notif_count }, dispatch }	= useValue();
	const {i18n}						= useTranslation()
	const USER_LANG                     = navigator.language || navigator.userLanguage;
    const APP_LANG = {
        'fr-FR'	: 'fr',
        'fr'	: 'fr',
        'en-US'	: 'en',
        'en'	: 'en'
    };

	const setAppLang = () => {
        if (localStorage.getItem('lang')) {
            i18n.changeLanguage(localStorage.getItem('lang'));
        } else {
            USER_LANG in APP_LANG ? localStorage.setItem('lang', APP_LANG[USER_LANG]) : localStorage.setItem('lang', 'fr');
        }
    }

	useEffect(()=>{
		if(notif_count === null){
			getNotifs(currentUser, dispatch)
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[])

	useEffect(() => {
        setAppLang();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

	return (
		<Box>
			<LanguagePopover />
			{currentUser.userRole === "Admin" && (notif_count !== null || notif_count !== 0) && <NotificationsPopover />}
			<IconButton onClick={(e) => setAnchorUserMenu(e.currentTarget)}>
				<Avatar src={currentUser?.photoURL} alt={currentUser?.name}>
					{currentUser?.firstName?.charAt(0).toUpperCase()}
				</Avatar>
			</IconButton>
			<UserMenu {...{ anchorUserMenu, setAnchorUserMenu }} />
		</Box>
	);
};
export default UserIcons;