import React from "react";
import {Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { styled } from '@mui/material/styles'
import ButtonBase from '@mui/material/ButtonBase';
import { useTranslation } from "react-i18next";

import Himg from "../../../images/hero_img.png"
import { useNavigate } from "react-router-dom";



const Hero = ({heroItems}) => {

  const { t } = useTranslation("global");
    const theme = useTheme();
    const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate()

    const ImageButton = styled(ButtonBase)(({ theme }) => ({
        position: 'relative',
        width: matchesSM ? '90%' : '40%',
        height: 200,
        [theme.breakpoints.down('sm')]: {
            height: 100,
        },
        '&:hover, &.Mui-focusVisible': {
            zIndex: 1,
            '& .MuiImageBackdrop-root': {
                opacity: 0,
            },
            '& .MuiImageMarked-root': {
                opacity: 0,
            },
            '& .MuiTypography-root': {
                border: '4px solid currentColor',
            },
        },

      }));

      
      const ImageSrc = styled('span')({
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundSize: 'cover',
        backgroundPosition: 'center 40%',
      });
      
      const Image = styled('span')(({ theme }) => ({
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.common.white,
      }));
      
      const ImageBackdrop = styled('span')(({ theme }) => ({
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: theme.palette.common.black,
        opacity: 0.05,
        transition: theme.transitions.create('opacity'),
      }));
      
      const ImageMarked = styled('span')(({ theme }) => ({
        height: 3,
        width: 18,
        backgroundColor: theme.palette.common.white,
        position: 'absolute',
        bottom: -2,
        left: 'calc(50% - 9px)',
        transition: theme.transitions.create('opacity'),
      }));
      


    return(
        <Grid container alignItems='center' alignContent='center' direction={{xs: 'column', sm: 'column', md: 'row', lg: 'row'}} justifyContent='space-around' sx={{px: {xs: '1rem',sm: '2rem', md: '5rem', lg: '10rem'}, py: '2rem', backgroundSize: 'cover'}}>
            <Grid item container justifyContent='space-between' xs={12} md={12} lg={6} direction='column'>
                <Grid item >
                    <Typography sx={{fontSize: '2rem', fontWeight: '700'}}>
                        {t('hero.Be familiar with the')}  <span style={{color: '#fff', backgroundColor: '#157200'}}>{t('hero.trade')}</span>  {t('hero.and')} <span style={{color: '#fff', backgroundColor: '#157200'}}>{t('hero.transport')}</span> {t('hero.regulations of the area in which I operate')}
                    </Typography>
                </Grid>
               
                <Grid item container alignContent='center' gap={1}  justifyContent='space-between' >
                {heroItems.map((item, i) => (
                    <ImageButton
                        focusRipple
                        key={item.title}
                        onClick={()=>navigate(item.link)}

                    >
                        <ImageSrc style={{ backgroundImage: `url(${item.img})` }} />
                        <ImageBackdrop className="MuiImageBackdrop-root" />
                        <Image>
                            <Typography
                            component="span"
                            variant="subtitle1"
                            color="inherit"
                            sx={{
                               
                                p: 4,
                                pt: 2,
                                pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                                fontSize: {xs: '1rem', sm: '1.7rem'}
                            }}
                            >
                            {item.title}
                            <ImageMarked className="MuiImageMarked-root" />
                            </Typography>
                        </Image>
                        </ImageButton>
                    ))}
                </Grid>

                <Grid item >
                    <Typography sx={{fontSize: '1.575rem', fontWeight: '500'}}>
                        {t('hero.To carry out my activities in complete')} <span style={{color: '#157200', fontWeight: '600'}}>{t('hero.legality')}</span> {t('hero.and')} <span style={{color: '#157200', fontWeight: '600'}}>{t('hero.peace of mind')}</span> {t('hero.and seize all the')} <span style={{color: '#157200', fontWeight: '600'}}>{t('opportunities')}</span> {t('hero.available to me')}
                    </Typography>
                   
                </Grid>
            </Grid>
            <Grid item sx={{display: {xs: 'none', sm: 'none', md: 'none', lg: 'flow'}}} xs={5}>
                <img src={Himg} style={{height: '30rem'}} alt="Hero" />
            </Grid>
        </Grid>
    )
}

export default Hero