import React from "react";
import { Button, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import EastIcon from '@mui/icons-material/East';
import { useNavigate } from "react-router-dom";

const Type = ({ types }) => {
    const {t} = useTranslation("global");
    const navigate = useNavigate()

    return(
        <Grid container justifyContent='space-around' direction={{xs: 'column', md: 'row'}} alignContent='space-between' sx={{px: {xs: '1rem',sm: '2rem', md: '5rem', lg: '10rem'}, backgroundColor: '#157200', py: '5rem'}} >
            <Grid item xs={5} container justifyContent='center' direction='column'>
                {types.map((type, i) => (
                    <Grid item key={i} sx={{py: '1.2rem', px: '1rem', backgroundColor: '#fff', borderLeft: '0.5rem solid', borderTopColor: '#FDF50D', borderLeftColor: `${type.color}`, mb: i<2 ? '1rem' : '0', boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)"}}>
                        <Typography sx={{fontWeight: '550'}}>
                            {type.title}
                        </Typography>
                    </Grid>
                ))}
            </Grid>
            <Grid item container sx={{pt: '1rem'}} justifyContent='space-between' xs={6} direction='column'>
                <Grid item sx={{pt: '1rem'}}>
                    <Typography sx={{fontWeight: '700', color: '#fff'}}>
                        {t('types.Everything you need to know to run a successful trade and transport business')}
                    </Typography>
                </Grid>
                <Grid item sx={{pt: '1rem'}}>
                    <Typography sx={{textAlign: 'justify', color: '#fff'}}>
                    {t("types.By listening to your concerns")}
                    </Typography>
                </Grid>
                <Grid item container gap={2} sx={{pt: '1rem'}}>
                    <Grid item>
                        <Button onClick={()=>navigate("/messages/commerce")} endIcon={<EastIcon />} sx={[{color: '#000', backgroundColor: '#FDE50D', fontWeight: '600'}, {'&:hover':{backgroundColor: '#FDEE62'}}]}>
                            {t('types.Messages about trade')}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button onClick={()=>navigate("/messages/transport")} variant="outlined" endIcon={<EastIcon />} sx={[{color: '#fff', borderColor: '#FDE50D', fontWeight: '600'}, {'&:hover':{backgroundColor: '#FDEE62'}}]}>
                            {t('types.Messages about transport')}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
export default Type