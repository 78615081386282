import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Avatar, Box, Button, Container, DialogContent, Grid, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Popover, Typography } from "@mui/material";
import OldPhone from '../../../images/old_phone.png'
import MobileApp from "../../../images/mobile_app.png"
import SoftPhone from "../../../images/Softphone.png"
import Arrow from '@mui/icons-material/ArrowDropDownSharp';
import PhoneIcon from '@mui/icons-material/Phone';
import { InstallMobile, Settings } from "@mui/icons-material";
// import apk from '../../../images/sim2g-dtrc.apk'
import { useNavigate } from "react-router-dom";

const Plateforme = ({ countries }) => {

    const { t } = useTranslation('global')

    const [ipAdress, setIpAdress] = useState('')
    const [geoInfo, setGeoInfo] = useState({})
    const [currentCountry, setCurrentCountry] = useState(null)
    const [anchorEl, setAnchorEl] = useState(null)

    const navigate = useNavigate()

    useEffect(() => {
        const getVisitorIp = async () => {
            try {
                const response = await fetch('https://api.ipify.org')
                const data = await response.text()

                setIpAdress(data)
            } catch (err) {
            }
        }

        const fetchIpInfo = async () => {
            try {
                const response = await fetch(`https://geolocation-db.com/json/345dd3d0-b984-11ee-af01-4784ec48a61d/${ipAdress}`)
                const data = await response.json()

                setGeoInfo(data)
            } catch (err) {
                console.log(err)
            }
        }
        const getCurrentCountry = () => {
            const selectedCountry = countries.filter(country => country.countryCode === geoInfo?.country_code?.toLowerCase())
            setCurrentCountry(selectedCountry[0])
        }

        getVisitorIp()
        if (ipAdress !== '') fetchIpInfo()
        if (geoInfo.length !== 0) getCurrentCountry()
    })

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;


    return (
        <Grid container direction='column' sx={{ py: '2rem' }}>
            <Grid item sx={{ py: 2 }}>
                <Typography sx={{ fontSize: '1.875rem', fontWeight: '700', textAlign: 'center' }}>
                    {t('plateforme.A flexible, multi-platform approach')}
                </Typography>
                <Typography sx={{ textAlign: 'center' }}>
                    {t('plateforme.In addition to this web platform, access key messages from regulatory texts through :')}
                </Typography>
            </Grid>

            <Grid item justifyContent='center' container>
                <Container sx={{ pb: 0 }}>
                    <Grid item container sx={{ pb: 0, height: '100%' }} spacing={2} alignItems='center' xs={12}>
                        <Grid item container xs={12} sm={12} justifyContent="space-between" md={6} sx={{ py: 2 }} lg={6} direction="column">
                            <Grid item>
                                <Typography sx={{ fontSize: '1.375rem', fontWeight: '700' }}>
                                    {t('plateforme.A mobile app')}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography sx={{ textAlign: 'justify', py: 2 }}>
                                    {t("plateforme.An Android mobile application that gives you access to key messages in text and audio format. In addition to the 4 main languages, you're entitled to at least 3 of your local languages. The application also lets you consult the content you've already downloaded over the Internet.")}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Button href="https://play.google.com/store/apps/details?id=com.cilss.app&hl=fr&gl=US" target='_blank' startIcon={<InstallMobile />}
                                    variant='contained' sx={{ textTransform: 'none', textDecoration: 'none', fontWeight: '600', color: 'primary' }} >
                                    {t("header.download_mobile_app")}
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ pb: 0, mb: 0 }} sm={12} md={6} lg={6}>
                            <img style={{ width: '100%', height: 'auto', py: 0, mb: -2 }} src={MobileApp} alt="L'application mobile" />
                        </Grid>
                    </Grid>
                </Container>
                <Box sx={{ width: '100%', backgroundColor: '#157200' }}>
                    <Container>
                        <Grid item container xs={12} sx={{ pb: '1.5rem', backgroundColor: '#157200', py: 2 }} alignItems='center' justifyContent='flex-end'>

                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <img src={OldPhone} style={{ height: 'auto', width: '70%' }} alt="Le cellulaire" />
                            </Grid>

                            <Grid item container xs={12} sm={12} md={6} lg={6} alignItems='center' justifyContent='space-between' sx={{ width: '13.45rem' }} direction='column'>
                                <Grid item>
                                    {currentCountry ? (
                                        <Button startIcon={<PhoneIcon />} endIcon={<img style={{ width: 30 }} src={(currentCountry?.flag)} alt="Drapeau du pays" />} variant="outlined" href={`tel:${currentCountry?.tollFree}`} sx={[{ fontSize: '1.375rem', fontWeight: '700', color: '#fff', borderRadius: '0', textTransform: 'none', borderColor: '#fff' }, { "&:hover": { backgroundColor: '#daf3d4', color: '#157200' } }]}>
                                            {currentCountry?.tollFree}
                                        </Button>
                                    ) : (
                                        <Box>
                                            <Button aria-describedby={id} endIcon={<Arrow />}
                                                variant="outlined" onClick={handleClick}
                                                sx={[
                                                    { fontSize: '1.375rem', color: '#fff', fontWeight: '600', borderRadius: '0', textTransform: 'none', borderColor: '#fff', width: '20rem' }, { "&:hover": { backgroundColor: '#daf3d4', color: '#157200' } }]}>
                                                {t('plateforme.A toll-free number')}
                                            </Button>
                                            <Popover id={id} anchorEl={anchorEl} onClose={handleClose}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                }} open={open}>
                                                <DialogContent>
                                                    <List dense>
                                                        {countries.map((country) => (
                                                            <ListItem key={country.tollFree}>
                                                                <ListItemButton href={country?.tollFree ? `tel:${country.tollFree}` : undefined}>
                                                                    <ListItemAvatar>
                                                                        <Avatar alt="drapeau du pays" src={country.flag} />
                                                                    </ListItemAvatar>
                                                                    <ListItemText>
                                                                        {country.tollFree}
                                                                    </ListItemText>
                                                                </ListItemButton>
                                                            </ListItem>
                                                        ))}
                                                    </List>
                                                </DialogContent>
                                            </Popover>
                                        </Box>
                                    )}
                                </Grid>
                                <Grid item sx={{ py: 2 }}>
                                    <Typography sx={{ fontSize: '1.375rem', fontWeight: '700', color: '#fff' }}>
                                        {t('plateforme.A toll-free number for each country')}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography sx={{ color: '#fff', textAlign: 'justify' }}>
                                        {t("plateforme.Each toll-free number gives access to an Interactive Voice Response (IVR) that guides callers to the information they need. The IVR is available in several languages. It's free to call, and no smartphone is required.")}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>

                <Container sx={{ pb: 0 }}>
                    <Grid item container sx={{ pb: 0 }} spacing={2} alignItems='center' xs={12}>

                        <Grid item container xs={12} sm={12} md={6} sx={{ py: 2 }} lg={6} direction="column">
                            <Grid item>
                                <Typography sx={{ fontSize: '1.375rem', fontWeight: '700' }}>
                                    {t('plateforme.low_cost_call_softphone')}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography sx={{ textAlign: 'justify', py: 2 }}>
                                    {t("plateforme.softphone_des")}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Button onClick={() => navigate('/qrcode')} variant="contained" startIcon={<Settings />} sx={{ textTransform: 'none', fontWeight: '600' }}>
                                    {t("plateforme.set_up_softphone")}
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ pb: 0, mb: 0 }} sm={12} md={6} lg={6}>
                            <img style={{ width: '100%', height: 'auto', py: 0, mb: -2 }} src={SoftPhone} alt="L'application mobile" />
                        </Grid>
                    </Grid>
                </Container>

            </Grid>
        </Grid>
    )
}
export default Plateforme