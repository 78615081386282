import axios from "../../api/axios"

export const patchData = async ({url, updatedFields, token}, dispatch) =>{
    try{
        const response = await axios.post(url, 
            updatedFields,
            {
                headers: {
                    'Content-Type': 'multipart/from-data',
                    'Authorization': `Token ${token}`
                },
            }
            )
        if(response){
            return response.data
        }
    } catch(err){
        dispatch({
            type: 'UPDATE_ALERT',
            payload: {
                open: true,
                severity: 'error',
                message: err.message
            }
        });
        return null
    }
}